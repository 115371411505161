/* eslint-disable max-len */
<template>
  <v-container>
    <router-view />
    <template v-if="workflow.workflowLoading">
      <v-layout align-center class="loader-layout">
        <v-flex>
          <pas-circular-loader :loadingMessage="`It'll Just Be A Moment...`" />
        </v-flex>
      </v-layout>
    </template>
    <template v-else>
      <div id="workflows">
        <WorkflowTitle :workflow="workflow" />

        <div id="idDivButtonRight" class="ma-auto">
          <pas-button
            v-if="canAccessCancelWorkflowBtn && workflowCanBeCancelled"
            @click="cancelWorkflow"
            class="inverted-btn px-5"
            id="id-cancel-workflow-btn"
          >Cancel workflow</pas-button>
          <pas-button
            v-if="isClientMaintenanceOrAnnualKyc"
            @click="navigateClientChangesPage"
            :disabled="!workflowCanBeCancelled"
            class="px-5 ml-1"
          >See Client Changes</pas-button>
          <pas-button
            v-if="isFatcaRenewalWorkflow"
            @click="openFatcaChanges"
            class="px-5 ml-1"
          >See FATCA Changes</pas-button>
          <pas-button
            v-if="showFundingDetailsButton"
            @click="showFundingDetails"
            :processing="isFundingDetailsLoading"
            class="px-5 ml-1"
            id="id-show-funding-details-btn"
          >Request Details</pas-button>
          <pas-button
            v-if="isIpsGroupWorkflow && householdId && ipsGroupGuid"
            @click="goToIpsGroup()"
            class="inverted-btn px-5"
          >View IPS Group</pas-button>
        </div>

        <ConfirmActionDialog
          @close="cancelWorkflowModalOpen = false"
          @ok="confirmDeleteWorkflow"
          :isOpen="cancelWorkflowModalOpen"
          :width="'400px'"
        >
          <template v-slot:header>Cancel workflow</template>
          Are you sure you want to cancel this workflow? It cannot be undone.
          <br />
          <br />
          <b>Note:</b> If there is a document package out for signature, it will be voided.
          <br />
          <br />
          <template v-slot:btn>Yes</template>
        </ConfirmActionDialog>

        <FundingDetails
          @close="fundingDetailsModalOpen = false"
          :transaction="fundingDetails"
          :isOpen="fundingDetailsModalOpen"
        />

        <v-card flat max-width="90rem" class="ma-auto">
          <template v-for="step in workflow.workflowSteps">
            <RiskQuestionnaireStep
              :key="step.id"
              v-if="step.name === 'risk_questionnaire'"
              :workflow="workflow"
              :step="step"
            />
            <AccountOpeningStep
              :key="step.id"
              v-else-if="step.name === 'account_opening'"
              :workflow="workflow"
              :step="step"
            />
            <EquifaxCheckStep
              :key="step.id"
              v-else-if="step.name === 'equifax_check'"
              :workflow="workflow"
              :step="step"
            />
            <AmlCheckStep
              :key="step.id"
              v-else-if="step.name === 'aml_check'"
              :workflow="workflow"
              :step="step"
            />
            <FCCProcessingStep
              :key="step.id"
              v-else-if="step.name === 'fcc_processing'"
              :workflow="workflow"
              :step="step"
            />
            <AdvisorApprovalStep
              :key="step.id"
              v-else-if="step.name === 'advisor_approval'"
              :workflow="workflow"
              :step="step"
            />
            <PMApprovalStep
              :key="step.id"
              v-else-if="step.name === 'pm_approval'"
              :workflow="workflow"
              :step="step"
            />
            <ComplianceApprovalStep
              :key="step.id"
              v-else-if="step.name === 'compliance_approval'"
              :workflow="workflow"
              :step="step"
            />
            <DocumentGenerationStep
              :key="step.id"
              v-else-if="
                step.name === 'document_generation' && isClientOnboarding
              "
              :workflow="workflow"
              :step="step"
            />
            <DocumentGenerationStepMMWorkflow
              :key="step.id"
              v-else-if="
                step.name === 'document_generation' && isManualWorkflow
              "
              :workflow="workflow"
              :step="step"
            />
            <DocumentGenerationStepKYCWorkflow
              :key="step.id"
              v-else-if="step.name === 'document_generation_kyc'"
              :workflow="workflow"
              :step="step"
              :isManualWorkflow="isManualWorkflow"
            />
            <ClientSigningStep
              :key="step.id"
              :isManualWorkflow="isManualWorkflow"
              v-else-if="step.name === 'client_signing'"
              :workflow="workflow"
              :step="step"
            />
            <AdvisorSigningStep
              :key="step.id"
              v-else-if="step.name === 'advisor_signing'"
              :workflow="workflow"
              :step="step"
            />
            <DocumentProcessingManualStep
              :key="step.id"
              v-else-if="
                step.name === 'document_processing' && isManualWorkflow
              "
              :workflow="workflow"
              :step="step"
            />
            <DocumentReviewStep
              :key="'documentReviewStep_' + step.id"
              v-else-if="step.name === 'review_and_approval'"
              :workflow="workflow"
              :step="step"
            />
            <FccPayloadManualStep
              :key="step.id"
              v-else-if="step.name === 'fcc_payload' && isManualWorkflow"
              :workflow="workflow"
              :step="step"
            />
            <PortfolioAssignmentStep
              :key="step.id"
              v-else-if="step.name === 'portfolio_assignment'"
              :workflow="workflow"
              :step="step"
            />
            <FatcaCheckStep
              :key="step.id"
              v-else-if="step.name === 'fatca_check'"
              :workflow="workflow"
              :step="step"
            />
            <CrsCheckStep
              :key="step.id"
              v-else-if="step.name === 'crs_check'"
              :workflow="workflow"
              :step="step"
            />
            <ClientConfirmationToolStep
              :key="step.id"
              v-else-if="step.name === 'client_info_confirmation'"
              :workflow="workflow"
              :step="step"
            />
            <FatcaCrsRemediationStep
              :key="step.id"
              v-else-if="step.name === 'fatca_crs_remediation'"
              :workflow="workflow"
              :step="step"
            />
            <ReceiveAccountNumberStep
              :key="step.id"
              v-else-if="step.name === 'receive_account_number'"
              :workflow="workflow"
              :step="step"
            />
            <KYCCustodianValidationStep
              :key="step.id"
              v-else-if="step.name === 'fcc_validation_kyc'"
              :workflow="workflow"
              :step="step"
            />
            <FccProcessRequestStep
              :key="step.id"
              v-else-if="step.name === 'fcc_process_request'"
              :workflow="workflow"
              :step="step"
              :isManualWorkflow="isManualWorkflow"
            />
            <ManualWorkflowStep
              :key="step.id"
              v-else-if="step.name.includes('manual_')"
              :workflow="workflow"
              :step="step"
            />
            <WorkflowStep
              v-else
              :step="step"
              :isCanceled="workflow.status === 'canceled'"
              :key="step.id"
            />
          </template>
        </v-card>
      </div>
    </template>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import { WORKFLOW } from 'Services/constants';
import { getAvatar } from 'Services/helpers/files';
import ConfirmActionDialog from 'Components/Modals/ConfirmActionDialog';
import acApi from 'Services/api';
import { fetchSystematicByWorkflowId, updateRequest, fetchRequestDetailsByWorkflowId } from 'Services/api/FundingVersion2';
import WorkflowStep from './WorkflowView/WorkflowStep';
import EquifaxCheckStep from './WorkflowView/EquifaxCheckStep';
import RiskQuestionnaireStep from './WorkflowView/RiskQuestionnaireStep';
import AccountOpeningStep from './WorkflowView/AccountOpeningStep';
import AmlCheckStep from './WorkflowView/AmlCheckStep';
import FCCProcessingStep from './WorkflowView/FCCProcessingStep';
import AdvisorApprovalStep from './WorkflowView/AdvisorApprovalStep';
import PMApprovalStep from './WorkflowView/PMApprovalStep';
import ComplianceApprovalStep from './WorkflowView/ComplianceApprovalStep';
import DocumentGenerationStep from './WorkflowView/DocumentGenerationStep';
import DocumentGenerationStepMMWorkflow from './WorkflowView/DocumentGenerationStepMMWorkflow';
import DocumentProcessingManualStep from './WorkflowView/DocumentProcessingManualStep';
import DocumentReviewStep from './WorkflowView/DocumentReviewStep';
import FccPayloadManualStep from './WorkflowView/FccPayloadManualStep';
import ClientSigningStep from './WorkflowView/ClientSigningStep';
import AdvisorSigningStep from './WorkflowView/AdvisorSigningStep';
import PortfolioAssignmentStep from './WorkflowView/PortfolioAssignmentStep';
import WorkflowTitle from './WorkflowView/WorkflowTitle';
import FatcaCheckStep from './WorkflowView/FatcaCheckStep';
import CrsCheckStep from './WorkflowView/CrsCheckStep';
import ClientConfirmationToolStep from './WorkflowView/ClientConfirmationToolStep';
import FatcaCrsRemediationStep from './WorkflowView/FatcaCrsRemediation';
import ReceiveAccountNumberStep from './WorkflowView/ReceiveAccountNumberStep';
import KYCCustodianValidationStep from './WorkflowView/KYCCustodianValidationStep';
import DocumentGenerationStepKYCWorkflow from './WorkflowView/DocumentGenerationStepKYCWorkflow';
import FccProcessRequestStep from './WorkflowView/FccProcessRequestStep';
import FundingDetails from './WorkflowView/FundingDetails';
import ManualWorkflowStep from './WorkflowView/ManualWorkflowStep';

export default {
  name: 'WorkflowView',
  components: {
    WorkflowTitle,
    AdvisorSigningStep,
    ClientSigningStep,
    DocumentGenerationStep,
    DocumentGenerationStepKYCWorkflow,
    DocumentGenerationStepMMWorkflow,
    DocumentProcessingManualStep,
    DocumentReviewStep,
    FccPayloadManualStep,
    ComplianceApprovalStep,
    AdvisorApprovalStep,
    PMApprovalStep,
    FCCProcessingStep,
    AmlCheckStep,
    AccountOpeningStep,
    RiskQuestionnaireStep,
    WorkflowStep,
    EquifaxCheckStep,
    PortfolioAssignmentStep,
    FatcaCheckStep,
    CrsCheckStep,
    ClientConfirmationToolStep,
    FatcaCrsRemediationStep,
    ReceiveAccountNumberStep,
    KYCCustodianValidationStep,
    FccProcessRequestStep,
    ConfirmActionDialog,
    FundingDetails,
    ManualWorkflowStep,
  },
  async mounted() {
    this.workflowID = this.$route.params.workflowID;
    Promise.all([
      this.$store.dispatch('fetchWorkflowV2IpsGroup', this.workflowID),
      this.$store.dispatch('fetchWorkflowV2Client', this.workflowID),
      this.$store.dispatch('fetchWorkflowV2Steps', this.workflowID),
      this.$store.dispatch('fetchWorkflowV2Details', this.workflowID),
    ]).then(() => {
      this.$store.dispatch('fetchClientDetails', this.clientID);
      if (this.isFundingWorkflow) {
        this.getFundingDetails();
      }
    }).catch((err) => {
      this.snackBar('fail', 'We had trouble fetching data. Please reload the page');
    });
  },
  data() {
    return {
      WORKFLOW,
      getAvatar,
      fundingDetails: {},
      cancelWorkflowModalOpen: false,
      fundingDetailsModalOpen: false,
      isFundingDetailsLoading: false,
    };
  },
  methods: {
    async sleep(delay) {
      return new Promise((resolve) => {
        setTimeout(resolve, delay);
      });
    },
    async refreshWorkflow() {
      this.$store.state.workflow.selectedWorkflow.id = Number(this.$route.params.workflowID);
      await this.sleep(2000);
      await Promise.all([
        this.$store.dispatch('fetchWorkflowV2Steps', this.workflowID),
        this.$store.dispatch('fetchWorkflowV2Details', this.workflowID),
      ]);
    },
    async processCancelledWfData() {
      // process associated workflow when wf gets cancelled
      if (this.isFundingWorkflow) {
        // mark funding request status as cancelled
        const fundingRequests = await fetchSystematicByWorkflowId(this.workflowID);
        if (fundingRequests.length) {
          const body = {
            request_id: fundingRequests[0].systematic,
            status: 'Inactive',
          };
          await updateRequest({ body });
        }
      }
    },
    cancelWorkflow() {
      const clientSignatureStepStatus = this.workflow.workflowSteps
        .find((wfStep) => wfStep.name === 'client_signing')?.status;

      const cannotCancelWorkflow = clientSignatureStepStatus === 'completed';

      if ((this.isClientMaintenanceOrAnnualKyc || !this.hasFullWorkflowDeletePermission) && cannotCancelWorkflow) {
        // workflow cannot be cancelled. Open info modal instead
        this.$store.commit('OPEN_MODAL', 'contact-ops-cancel-workflow');
      } else {
        this.cancelWorkflowModalOpen = true;
      }
    },
    async getFundingDetails() {
      this.isFundingDetailsLoading = true;
      const workflowId = this.$route.params.workflowID;
      try {
        const response = await fetchRequestDetailsByWorkflowId(workflowId);
        this.fundingDetails = response;
      } finally {
        this.isFundingDetailsLoading = false;
      }
    },
    async showFundingDetails() {
      this.fundingDetailsModalOpen = true;
    },
    async openFatcaChanges() {
      this.$router.push({ path: `/workflowV2/${this.workflow.id}/fatca-changes` });
    },
    async confirmDeleteWorkflow() {
      const payload = {
        message: `workflow.${this.workflowType}.cancel`,
        data: {
          workflow_id: this.workflowID,
          notifyOps: !this.hasFullWorkflowDeletePermission,
        },
      };

      await acApi.post('/advisor_portal/send_message', payload);
      await this.processCancelledWfData();

      this.cancelWorkflowModalOpen = false;

      await this.refreshWorkflow();
      if (!this.isIpsGroupWorkflow) {
        // Stay on workflow page for IPS group
        this.$router.push({ path: `/clients/${this.clientID}` });
      }
    },
    navigateClientChangesPage() {
      this.$router.push({ path: `/clients/${this.clientID}/client-changes` });
    },
    snackBar(type, text) {
      this.$store.dispatch('setSnackbar', { text, type });
      this.$store.dispatch('flipSnackbarSwitch');
    },
    goToIpsGroup() {
      const ipsGroupPath = `/households/${this.householdId}/ips_group/${this.ipsGroupGuid}`;
      this.$router.push({ path: ipsGroupPath });
    },
  },
  computed: {
    ...mapGetters([
      'householdId',
      'ipsGroupGuid',
      'isClientMaintenanceOrAnnualKyc',
      'isClientOnboarding',
      'isFundingWorkflow',
      'isIpsGroupWorkflow',
      'isManualWorkflow',
      'workflowCanBeCancelled',
      'workflowType',
      'hasFullWorkflowDeletePermission',
      'isFatcaRenewalWorkflow',
    ]),
    workflow() {
      return this.$store.state.workflow.selectedWorkflow;
    },
    clientID() {
      return this.client?.id;
    },
    client() {
      return this.$store.state.workflow.selectedWorkflow.clientInfo;
    },
    isClientOnboardingJointWorkflow() {
      const hasClientSigningStep = this.workflow.workflowSteps.some((step) => step.name === 'client_signing');
      return this.isClientOnboarding && !hasClientSigningStep;
    },
    canAccessCancelWorkflowBtn() {
      return this.$store.state.permissions.submodules?.[this.workflowType]?.delete
        && !(this.isClientOnboardingJointWorkflow && !this.hasFullWorkflowDeletePermission);
    },
    showFundingDetailsButton() {
      return this.isFundingWorkflow && Object.keys(this.fundingDetails).length;
    },
  },
};
</script>

<style scoped>
#idDivButtonRight {
  text-align: right;
  max-width: 90rem;
}
</style>
