<template>
  <div>
    <div class="mt-4 px-3">
      <span class="strong-text">Name:  </span>
      <PasEditableSpan
        :value="feeGroup.name"
        :editableSpanClass="'solid-white-text'"
        :rules="[v.isRequired()]"
        :style="{ display: 'inline' }"
        @editSaved="$emit('update', { id: feeGroup.id, name: $event })"
        :disabled="disabled"
        class="mb-3"
      />
    </div>

    <div class="mt-4 px-3" v-if="feeGroupDefaultManagementFeeEnabled">
      <span class="strong-text">Default Management Fee: </span>
      <br />
      <span class="text">The Default Management Fee sets the standard rate for all accounts in a Fee Group
        and applies automatically to new accounts. If the default fee changes, accounts using it will update
        automatically, but any fees you’ve set as exceptions or prior to the default won’t be affected.
      </span>
      <v-select
        :items="managementFees"
        :value="feeGroup.default_management_fee_schedule"
        @input="$emit('update', { id: feeGroup.id, default_management_fee_schedule: $event })"
        :disabled="disabled"
        class="select-default-fee-management"
      />
    </div>

    <div class="strong-text px-3">Membership:</div>
    <v-data-table
      :headers="headers"
      :items="items"
      hide-actions
      class="overflow-x-scroll"
    >
      <template v-slot:items="props">
        <td class="text-xs-left">{{ props.item.name }}</td>
        <td class="text-xs-rleft">{{ props.item.relationship }}</td>
        <td class="text-xs-rleft">{{ props.item.status }}</td>
      </template>
    </v-data-table>
  </div>
</template>

<script>

import v from 'Services/validation';

export default {
  name: 'FeeGroupDetails',
  props: {
    feeGroup: Object,
    relationships: Object,
    managementFees: Array,
    disabled: Boolean,
  },
  data() {
    return {
      headers: [
        {
          text: 'Client Name',
          align: 'left',
          sortable: false,
          value: 'name',
        },
        {
          text: 'Relationship',
          sortable: false,
          value: 'relationship',
        },
        {
          text: 'Status',
          sortable: false,
          value: 'status',
        },
      ],
      v,
    };
  },
  computed: {
    items() {
      return this.feeGroup.clients.map((c) => ({
        name: c.client_display_name,
        relationship: this.relationships[c.client] && this.relationships[c.client].type ? this.relationships[c.client].type : '---',
        status: !c.client_status ? '---' : c.client_status.charAt(0).toUpperCase() + c.client_status.slice(1),
      }));
    },
    feeGroupDefaultManagementFeeEnabled() {
      return this.$store.state.app.features.fee_group_default_management_fee;
    },
  },
};
</script>

<style>
.overflow-x-scroll {
  overflow-x: scroll;
}
.select-default-fee-management {
    max-width: 200px !important;
    min-width: 100px !important;
}

</style>
