<template>
  <div v-if="clientId">
    <PasPageHeader :path="`/clients/${clientId}`" v-if="!hideHeader">
      {{clientDisplayName}}
    </PasPageHeader>
    <div class="tabs-page-view">
      <PasAccordion v-if="feeGroupsEnabled && canViewFeeGroups">
        <template v-slot:panelTitle>
          <PasPageSubtitle>Fee Group</PasPageSubtitle>
        </template>
        <template v-slot:panelContent>
          <ClientFeeGroup :accountOpening="false" />
        </template>
      </PasAccordion>
      <PasAccordion v-if="feeScheduleEnabled">
        <template v-slot:panelTitle>
          <PasPageSubtitle>Fee Schedules Summary</PasPageSubtitle>
        </template>
        <template v-slot:panelContent>
          <AccountFeeSchedules />
        </template>
      </PasAccordion>
      <PasAccordion v-if="feeScheduleEnabled">
        <template v-slot:panelTitle>
          <PasPageSubtitle>Fee Schedules Breakdown</PasPageSubtitle>
        </template>
        <template v-slot:panelContent>
          <ClientFeeSchedules />
        </template>
      </PasAccordion>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ClientFeeGroup from 'Components/Shared/FeeManagement/ClientFeeGroup';
import ClientFeeSchedules from 'Components/Shared/FeeManagement/ClientFeeSchedules';
import AccountFeeSchedules from 'Components/Shared/FeeManagement/AccountFeeSchedules';

export default {
  name: 'FeeManagementTab',
  props: {
    clientID: {
      type: String,
      default: '',
    },
    hideHeader: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    AccountFeeSchedules,
    ClientFeeGroup,
    ClientFeeSchedules,
  },
  methods: {
    ...mapGetters([
      'canViewFeeGroups',
      'canViewPlanningSchedules',
      'canViewManagementSchedules',
      'canViewSubadvisorSchedules',
    ]),
    feeGroupsEnabled() {
      return this.$store.state.app.features.fee_groups && this.canViewFeeGroups
      && (this.activeClient.client.client_status === 'active' || this.activeClient.client.client_status === 'prospect');
    },
    feeScheduleEnabled() {
      return this.$store.state.app.features.fee_schedules
      && (this.activeClient.client.client_status === 'active' || this.activeClient.client.client_status === 'prospect')
      && (this.canViewPlanningSchedules || this.canViewManagementSchedules || this.canViewSubadvisorSchedules);
    },
  },
  computed: {
    activeClient() {
      return this.$store.state.clients.activeClient;
    },
    clientId() {
      return this.$store.state.clients.activeClient?.client?.id;
    },
    clientDisplayName() {
      return this.activeClient?.client?.display_name;
    },
  },
  watch: {
    clientID: {
      immediate: true,
      async handler() {
        await this.$store.dispatch('fetchClientDetails', this.clientID);
      },
    },
  },
};
</script>

<style>
  div.tabs-page-view {
    box-shadow: none !important;
  }
</style>
