<template>
  <PRow class="no-gutters">
    <PColumn>
      <PInputField
        class="client-list-search-bar ml-5"
        v-model="searchBarValue"
        placeholder="Search Clients"
        prependIcon="search"
        type="text"
        :clearable="true"
        @input="$emit('input', searchForClients(searchBarValue))"
      />
    </PColumn>

    <DropdownFilter
      :loading="householdLoading"
      :dropdownLabel="householdFilterLabel"
      :checkboxOptions="householdOptions"
      :value="householdFilter"
      data-testid="household-filter-dropdown"
      @change="updateHouseholdFilter($event)"
    />

    <DropdownFilter
      :dropdownLabel="statusFilterLabel"
      :checkboxOptions="statusOptions"
      :value="statusFilter"
      data-testid="status-filter-dropdown"
      @change="updateStatusFilter($event)"
    />

    <DropdownFilter
      :loading="advisorLoading"
      :dropdownLabel="advisorFilterLabel"
      :checkboxOptions="advisorOptions"
      :value="advisorFilter"
      data-testid="advisor-filter-dropdown"
      @change="updateAdvisorFilter($event)"
    />
    <DropdownFilter
      :loading="teamLoading"
      :dropdownLabel="teamFilterLabel"
      :checkboxOptions="teamOptions"
      :value="teamFilter"
      data-testid="team-filter-dropdown"
      @change="updateTeamFilter($event)"
    />
    <PButton
      :isIconOnly="true"
      class="mr-1"
      data-testid="clear-filters-button"
      appendIcon="filter-circle-xmark"
      @click="clearFilters"
      v-if="filtersApplied"
      variant="primary"
    />
  </PRow>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import _ from 'lodash';
import DropdownFilter from '../../DropdownFilter';
import { generateFilterLabel } from '../../utils';

const clientsListVuex = createNamespacedHelpers('clients-list');

export default {
  name: 'ClientsSearch',
  components: {
    DropdownFilter,
  },
  data() {
    return {
      searchBarValue: '',
    };
  },
  computed: {
    ...clientsListVuex.mapState([
      'clientsList',
      'householdList',
      'teamList',
      'advisorList',
      'teamLoading',
      'advisorLoading',
      'householdLoading',
      'teamFilter',
      'statusFilter',
      'advisorFilter',
      'householdFilter',
    ]),
    householdOptions() {
      return this.householdList?.length ? this.householdList.map((household) => ({
        text: household.nickname,
        value: household.guid,
      })).sort((a, b) => {
        if (a.value === 'UNASSIGNED') return -1;
        if (b.value === 'UNASSIGNED') return 1;
        return a.text?.localeCompare(b.text);
      }) : [];
    },
    statusOptions() {
      return [
        { text: 'Active', value: 'active' },
        { text: 'Prospect', value: 'prospect' },
        { text: 'Other Contact', value: 'other_contact' },
      ];
    },
    advisorOptions() {
      return this.advisorList ? this.advisorList.map((advisor) => ({
        text: advisor.name,
        value: advisor.guid,
      })) : [];
    },
    teamOptions() {
      return this.teamList ? this.teamList.map((team) => ({
        text: team.name,
        value: team.guid,
      })) : [];
    },
    householdFilterLabel() {
      return generateFilterLabel(this.householdFilter, this.householdOptions, 'Household');
    },
    statusFilterLabel() {
      return generateFilterLabel(this.statusFilter, this.statusOptions, 'Status');
    },
    advisorFilterLabel() {
      return generateFilterLabel(this.advisorFilter, this.advisorOptions, 'Advisor');
    },
    teamFilterLabel() {
      return generateFilterLabel(this.teamFilter, this.teamOptions, 'Team');
    },
    filtersApplied() {
      return (this.householdFilter?.length > 0
        || this.statusFilter?.length > 0
        || this.advisorFilter?.length > 0
        || this.teamFilter?.length > 0
      );
    },

  },
  methods: {
    ...clientsListVuex.mapActions([
      'fetchClientsList',
      'setTeamFilter',
      'setStatusFilter',
      'setAdvisorFilter',
      'setHouseholdFilter',
    ]),
    ...clientsListVuex.mapMutations(['SET_SEARCH_VAL']),
    updateHouseholdFilter(households) {
      if (_.isEqual(households, this.householdFilter)) { return; }
      this.setHouseholdFilter(households);
      this.fetchClientsList({ resetClientList: true });
    },
    updateStatusFilter(statuses) {
      if (_.isEqual(statuses, this.statusFilter)) { return; }
      this.setStatusFilter(statuses);
      this.fetchClientsList({ resetClientList: true });
    },
    updateAdvisorFilter(advisors) {
      if (_.isEqual(advisors, this.advisorFilter)) { return; }
      this.setAdvisorFilter(advisors);
      this.fetchClientsList({ resetClientList: true });
    },
    updateTeamFilter(teams) {
      if (_.isEqual(teams, this.teamFilter)) { return; }
      this.setTeamFilter(teams);
      this.fetchClientsList({ resetClientList: true });
    },
    clearFilters() {
      this.setHouseholdFilter([]);
      this.setStatusFilter([]);
      this.setAdvisorFilter([]);
      this.setTeamFilter([]);
      this.fetchClientsList({ resetClientList: true });
    },
    searchForClients: _.debounce(async function (searchValue) {
      this.SET_SEARCH_VAL(searchValue);
      this.fetchClientsList({ resetClientList: true });
    }, 300),
  },
};

</script>
