<template>
  <PRow class="d-flex flex-nowrap justify-content-between">
    <PInputField
      class="w-100 household-list-search-bar ml-6"
      v-model="searchBarValue"
      placeholder="Search Households"
      prependIcon="search"
      type="text"
      :clearable="true"
      @input="$emit('input', searchForHouseholds(searchBarValue))"
    />
    <DropdownFilter
      :dropdownLabel="teamFilterLabel"
      :checkboxOptions="teamOptions"
      :value="teamFilter"
      @change="updateTeamFilter($event)"
    />
    <DropdownFilter
      :dropdownLabel="advisorFilterLabel"
      :checkboxOptions="advisorOptions"
      :value="advisorFilter"
      @change="updateAdvisorFilter($event)"
    />
    <PButton
      :isIconOnly="true"
      class="mr-1"
      appendIcon="filter-circle-xmark"
      @click="clearFilters"
      v-if="filtersApplied"
      variant="primary"
    />
  </PRow>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import _ from 'lodash';
import DropdownFilter from '../../DropdownFilter';
import { generateFilterLabel } from '../../utils';

const householdsVueX = createNamespacedHelpers('households');
const clientsListVuex = createNamespacedHelpers('clients-list');

export default {
  name: 'HouseholdListSearch',
  components: {
    DropdownFilter,
  },
  data() {
    return {
      searchBarValue: '',
    };
  },
  computed: {
    ...householdsVueX.mapState(['householdList', 'loading', 'teamFilter',
      'advisorFilter']),
    ...clientsListVuex.mapState([
      'teamList',
      'advisorList',
    ]),
    advisorOptions() {
      return this.advisorList ? this.advisorList.map((advisor) => ({
        text: advisor.name,
        value: advisor.guid,
      })) : [];
    },
    teamOptions() {
      return this.teamList ? this.teamList.map((team) => ({
        text: team.name,
        value: team.guid,
      })) : [];
    },
    advisorFilterLabel() {
      return generateFilterLabel(this.advisorFilter, this.advisorOptions, 'Advisor');
    },
    teamFilterLabel() {
      return generateFilterLabel(this.teamFilter, this.teamOptions, 'Team');
    },
    filtersApplied() {
      return (this.advisorFilter?.length > 0
        || this.teamFilter?.length > 0
      );
    },
  },
  methods: {
    ...householdsVueX.mapActions(['fetchHouseholdList', 'removeClient', 'setAdvisorFilter',
      'setHouseholdFilter', 'setTeamFilter']),
    ...householdsVueX.mapMutations(['SET_SEARCH_VAL']),
    dropdownShouldOpen(VueSelect) {
      return false;
    },
    updateAdvisorFilter(advisors) {
      if (_.isEqual(advisors, this.advisorFilter)) { return; }
      this.setAdvisorFilter(advisors);
      this.fetchHouseholdList({ resetHouseholdList: true });
    },
    updateTeamFilter(teams) {
      if (_.isEqual(teams, this.teamFilter)) { return; }
      this.setTeamFilter(teams);
      this.fetchHouseholdList({ resetHouseholdList: true });
    },
    searchForHouseholds: _.debounce(async function (searchValue) {
      this.SET_SEARCH_VAL(searchValue);
      this.fetchHouseholdList({ resetHouseholdList: true });
    }, 300),
    clearFilters() {
      this.setAdvisorFilter([]);
      this.setTeamFilter([]);
      this.fetchHouseholdList({ resetHouseholdList: true });
    },

},
}

</script>
