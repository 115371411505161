import api from 'Services/apiV2';

export const fetchRrifPayments = (clientId) => api.get('/get_rrifpmt_by', { context: 'client_id', value: clientId });

export const fetchSystematics = (clientId) => api.get('/get_systematics_by/', { pull_by: 'client_id', value: clientId });

export const fetchClientTaxAns = (clientId) => api.get(`/client_tax_confirmation_answers/?client_id=${clientId}`);

export const fetchClientRiskProfile = (clientId) => api.get('/client_risk_profile/', {
  client_id: clientId,
});
