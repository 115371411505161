import api from 'Services/apiV2';
import acApi from '../api';

import { LIST_VALID_ACCOUNT_STATUS } from '../../../constants/account_status';

const axonUrl = localStorage.getItem('axonUrl');

export const fetchFundingTypes = () => api.get('/funding/v1/types', undefined, undefined, axonUrl)
  .then((res) => res.result).catch((err) => err);

export const fetchFundingSubtypes = (clientId, typeId) => api
  .get('/funding/v1/subtypes/', { type_id: typeId, client_id: clientId }, undefined, axonUrl)
  .then((res) => res.result)
  .catch((err) => err);

export const fetchFundingFrequencies = () => api.get('/funding/v1/frequencies/', undefined, undefined, axonUrl)
  .then((res) => res.result).catch((err) => err);

export const fetchInvestmentAcctsByClient = (clientId, type = undefined) => api
  .get('/accounts/v1/accounts/', { client_id: clientId, types: type, status: LIST_VALID_ACCOUNT_STATUS.join(',') }, undefined, axonUrl)
  .then((res) => res.result).catch((err) => err);

export const fetchAllClientInvestmentAccts = (clientId) => api
  .get('/accounts/v1/accounts/', { related_to_client_id: clientId, status: LIST_VALID_ACCOUNT_STATUS.join(',') }, undefined, axonUrl)
  .then((res) => res.result)
  .catch((err) => err);

export const fetchBankAcctsByClient = (clientId) => api.get('/banking/v1/bank_accounts/', { client_id: clientId }, undefined, axonUrl)
  .then((res) => res.result.filter((acc) => acc.status !== 'inactive')).catch((err) => err);

export const fetchWithdrawalTypes = () => api.get('/funding/v1/reasons', undefined, undefined, axonUrl)
  .then((res) => res.result).catch((err) => err);

export const fetchWithdrawalTypesForSubtype = (typeId) => api.get('/funding/v1/withdrawal_types/', { subtype_id: typeId }, undefined, axonUrl)
  .then((res) => res.result).catch((err) => err);

export const fetchPaymentMethods = (subtypeId) => api.get('/funding/v1/payment_methods/', { subtype_id: subtypeId }, undefined, axonUrl)
  .then((res) => res.result).catch((err) => err);

export const fetchWithholdingTaxTypes = () => api.get('/funding/v1/withholding_tax_types/', undefined, undefined, axonUrl)
  .then((res) => res.result).catch((err) => err);

export const fetchCurrency = () => api.get('/funding/v1/currency/', undefined, undefined, axonUrl)
  .then((res) => res.result).catch((err) => err);

export const fetchRecipientTypes = () => api.get('/funding/v1/resp_withdrawal_recipient_types/', undefined, undefined, axonUrl)
  .then((res) => res.result).catch((err) => err);

export const postRequest = ({ requestedScope, body }) => api.post(`/funding/v1/requests/${requestedScope ? `?request_scope=${requestedScope}` : ''}`, body, axonUrl)
  .then((res) => res.result);

export const postComplexFundingRequest = ({ requestedScope, body }) => api.post(`/funding/v1/complex_funding_requests/${requestedScope ? `?request_scope=${requestedScope}` : ''}`, body, axonUrl)
  .then((res) => res.result);

export const postInternalTransferRequest = ({ requestedScope, body }) => api.post(`/funding/v1/internal_transfers/${requestedScope ? `?request_scope=${requestedScope}` : ''}`, body, axonUrl)
  .then((res) => res.result);

export const postExternalTransferRequest = ({ requestedScope, body }) => api.post(`/funding/v1/external_transfers/${requestedScope ? `?request_scope=${requestedScope}` : ''}`, body, axonUrl)
  .then((res) => res.result);

export const submitExternalTransfersRequest = ({ externalTransferIds }) => api.post(`/funding/v1/external_transfers/submit/?external_transfer_ids=${externalTransferIds}`, undefined, axonUrl)
  .then((res) => res.result);

export const fetchExternalTransferRequests = ({
  clientId, externalTransferId = null, limit = null, status = null, subtype = null,
}) => api.get('/funding/v1/external_transfers/', {
  client_id: clientId,
  external_account_transfer_id: externalTransferId,
  limit,
  status,
  subtype,
}, undefined, axonUrl)
  .then((res) => res.result);

export const fetchStagedExternalTransfers = (clientId) => fetchExternalTransferRequests({
  clientId,
  status: 'staged',
  subtype: 'transfer-in',
});

export const updateInternalTransferRequest = ({ body }) => api.put('/funding/v1/internal_transfers/', body, axonUrl)
  .then((res) => res.result);

export const updateExternalTransferRequest = ({ body }, transferId) => api.put(`/funding/v1/external_transfers/${transferId}/`, body, axonUrl)
  .then((res) => res.result);

export const deleteExternalTransferRequest = (transferId) => api.del(`/funding/v1/external_transfers/${transferId}/`, axonUrl)
  .then((res) => res.result);

export const updateRequest = ({ body }) => api.put('/funding/v1/requests/', body, axonUrl)
  .then((res) => res.result).catch((err) => {
    throw new Error(err);
  });

export const updateComplexFundingRequest = ({ body }) => api.put('/funding/v1/complex_funding_requests/', body, axonUrl)
  .then((res) => res.result).catch((err) => err);

export const createWorkflow = ({ body }) => api.post('/funding/v1/workflow/', body, axonUrl)
  .then((res) => res.result).catch((err) => err);

export const sendMessage = ({ body }) => acApi.post('/advisor_portal/send_message', body)
  .then((res) => res.result).catch((err) => err);

export const fetchFundingTransactions = ({
  clientId, timeRange, transactionType, accountType, transactionStatus,
}) => api.get('/funding/v1/transactions/', {
  client_id: clientId,
  days: timeRange,
  frequency_description: transactionType,
  account_types: accountType,
  transaction_status: transactionStatus,
}, undefined, axonUrl)
  .then((res) => res.result).catch((err) => err);

export const fetchFundingRequests = ({
  clientId, timeRange, transactionType, accountType, status, frequency, limit,
}) => api.get('/funding/v1/requests/', {
  client_id: clientId,
  days: timeRange,
  frequency_description: transactionType,
  account_types: accountType,
  status,
  frequency,
  limit: limit || 100,
}, undefined, axonUrl)
  .then((res) => res.result).catch((err) => err);

export const fetchWorkflowBySystematicId = (systematicId) => api.get(`/new_workflow_systematics/?systematic_id=${systematicId}`)
  .then((res) => res.results).catch((err) => err);

export const fetchSystematicByWorkflowId = (workflowId) => api.get(`/new_workflow_systematics/?workflow_id=${workflowId}`)
  .then((res) => res.results).catch((err) => err);

export const fetchSystematicById = (systematicId) => api.get(`/systematics/${systematicId}/`)
  .then((res) => res).catch((err) => err);

export const fetchRequestDetailsByWorkflowId = (workflowId) => api.get('/funding/v1/new_workflow_request_details/', { workflow_id: workflowId }, undefined, axonUrl)
  .then((res) => res.result);

export const fetchComplexFundingById = (fundingRequestId) => api.get('/funding/v1/complex_funding_requests/', { complex_funding_request_id: fundingRequestId }, undefined, axonUrl)
  .then((res) => res).catch((err) => err);

export const cancelFundingWorkflow = async (workflowId) => {
  const messageType = 'workflow.funding.cancel';
  const payload = {
    message: messageType,
    data: {
      workflow_id: workflowId,
    },
  };
  return acApi.post('/advisor_portal/send_message', payload);
};
