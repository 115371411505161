<template>
  <v-layout column>
    <v-flex>
      <v-layout row>
        <v-flex xs8>
          <v-layout justify-start row>
            <v-flex class="cursor-pointer no-flex-grow">
              <pas-page-title id="display-name">
                <router-link :to="editClientRoute(activeClient.client)">{{pageTitle}}</router-link>
              </pas-page-title>
            </v-flex>
            <v-flex sx2 ml-4 />
          </v-layout>
          <div class="light-text employment-line">
            <p>
              <pas-status-bullet
                v-if="statusMap[clientInfo.client_status]"
                :color="statusMap[clientInfo.client_status].color"
                :value="statusMap[clientInfo.client_status].value"
                :style="{ marginBottom: '2px !important' }"
              />
              <span :style="{ position: 'relative', left: '-3px' }">{{clientStatus[clientInfo.client_status]}}</span>
              <template>
                <span>&nbsp;&middot;&nbsp;</span>
                <span>{{employmentLine}}</span>
                <router-link
                  v-if="!employment || (employment && ![3, 4].includes(employment.employment_status) && (!employment.occupation || !employment.employer))"
                  :to="editClientRoute(activeClient.client)"
                  class="action-text underline-hover"
                >Edit Details</router-link>
              </template>
            </p>
          </div>
          <div class="light-text">
            <span class="strong-text">Client Type: </span><span>{{clientTypeText[activeClient.client.client_type]}}</span>
          </div>
          <div class="light-text">
            <span class="strong-text">Advisor: </span><span>{{this.getAdvisorName()}}</span>
          </div>
          <div class="light-text">
            <span class="strong-text">Advisor Team: </span><span>{{advisorTeam}}</span>
          </div>
          <div class="light-text" v-if="!hasFeatureFlag('client_data_hierarchy')">
            <span class="strong-text">Advisor Code: </span>
            <span>{{clientInfo.rep_code ? clientInfo.rep_code : "Not Available"}}</span>
          </div>
          <div class="light-text" v-else>
            <span class="strong-text">PM Team: </span><span>{{pmTeamName}}</span>
          </div>
          <div class="light-text" v-if="hasFeatureFlag('pm_assignment')">
            <span class="strong-text">Portfolio Manager </span>
            <span>{{pmName}}</span>
          </div>
          <div class="light-text" v-if="clientInfo.program">
            <span class="strong-text" data-testid="client-program">
              {{hasFeatureFlag('sub_advisor_consent') ? 'Service Model: ': 'Program: '}}</span>
              <span>{{ hasFeatureFlag('sub_advisor_consent') ? serviceModelName : programName }}</span>
          </div>
        </v-flex>
      </v-layout>
      <v-layout row mt-2>
        <v-flex xs6 md4>
          <v-layout row align-center justify-left>
            <v-flex class="phone-fab-flex" @dblclick="launchPhone()">
              <PasFab :disabled="true" id="phone-fab">
                <v-icon>mdi-phone</v-icon>
              </PasFab>
            </v-flex>
            <v-flex>
              <template v-if="fieldsInEditMode.includes('phone')">
                <v-form
                  ref="phoneForm"
                  lazy-validation
                  v-model="phoneValid"
                  @submit.prevent=""
                >
                  <v-text-field
                    type="text"
                    v-click-outside="savePhone"
                    v-model="preferredPhoneNoLocalClone"
                    class="light-text inline-edit-field input-field no-border-input-field phone-edit"
                    v-if="fieldsInEditMode.includes('phone')"
                    @keyup.enter="savePhone"
                    :rules="[v.emptyOrPhone]"
                    :mask="'phone'"
                    outline
                    single-line
                    ref="phoneEditField"
                  />
                </v-form>
              </template>
              <span
                v-else
                class="light-text editable-span"
                @dblclick="togglePhoneNumber()"
              >
                <span class="phone-label-prefix">
                  {{preferredPhoneNoLabel}}
                </span> {{preferredPhoneNoLocalClone ? `${phoneSpan}` : 'No # on record'}}
              </span>
            </v-flex>
          </v-layout>
        </v-flex>
        <v-flex xs6 md8 text-xs-left>
          <v-layout row align-center justify-left>
            <v-flex class="email-fab-flex" @dblclick="launchEmail">
              <PasFab :disabled="true" id="email-fab">
                <v-icon>mdi-email-outline</v-icon>
              </PasFab>
            </v-flex>
            <v-flex>
              <template v-if="fieldsInEditMode.includes('email')">
                <v-form
                  ref="emailForm"
                  lazy-validation
                  v-model="phoneValid"
                  @submit.prevent=""
                >
                  <v-text-field
                    type="email"
                    v-click-outside="saveEmail"
                    v-model="editedClient.client.email"
                    class="light-text inline-edit-field input-field no-border-input-field"
                    v-if="fieldsInEditMode.includes('email')"
                    @keyup.enter="saveEmail"
                    :rules="[v.emptyOrEmail]"
                    outline
                    single-line
                    ref="emailEditField"
                  />
                </v-form>
              </template>
              <span
                v-else
                class="light-text editable-span"
                @dblclick="toggleEmailEdit()"
              >
                <span class="phone-label-prefix"> E: </span>
                {{editedClient.client.email ? `${editedClient.client.email}` : 'No email on record'}}
                <v-icon
                  small
                  class="pl-1 pb-1"
                  v-if="hasEmailChangeRequestPending"
                  @click="emailChangeModal = true">
                  mdi-information
                </v-icon>
              </span>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
      <v-layout mt-3>
        <v-flex xs12>
          <v-form
            ref="quicknotesForm"
            lazy-validation
            @submit.prevent=""
            full-width
            v-if="fieldsInEditMode.includes('notes') && canEditNotes"
          >
            <v-textarea
              v-model="editedClient.client.quick_notes"
              full-width
              outline
              class="quick-notes"
              no-resize
              v-click-outside="saveNotes"
              :placeholder="`Write notes here...`"
            />
          </v-form>
          <div
            class="quick-notes-display light-text"
            v-else-if="canEditNotes"
            @dblclick="toggleEditMode('notes')"
          >
            {{clientInfo.quick_notes ? clientInfo.quick_notes : `Double click to create notes...`}}
          </div>
        </v-flex>
      </v-layout>
    </v-flex>
    <EmailChangeRequest
      :isOpen="emailChangeModal"
      :newEmail="editedClient.client.email"
      :client="activeClient.client"
      :emailRequestStatus="activeClient.emailChangeRequestStatus"
      @close="closeEmailChangeModal"
    />
  </v-layout>
</template>

<script>

import EmailChangeRequest from 'Components/Modals/EmailChangeRequest';
import { CLIENTSTATUS } from 'Services/constants';
import ClickOutside from 'vue-click-outside';
import { fetchTeamById } from 'Services/api/ClientDataHierarchy';
import { fetchAdvisor } from 'Services/api/PlatformUser';
import { editClientRoute } from 'Services/mixins';
import v from 'Services/validation';
import { mapState, mapGetters } from 'vuex';
import _ from 'lodash';

export default {
  name: 'profile-bio',
  components: {
    EmailChangeRequest,
  },
  directives: {
    ClickOutside,
  },
  mixins: [editClientRoute],
  props: {
    activeClient: Object,
    employment: Object,
    workAddress: Object,
  },
  data() {
    return {
      statusMap: {
        professional: {
          color: 'yellow',
          value: 100,
        },
        prospect: {
          color: 'green',
          value: 50,
        },
        inactive: {
          color: 'red',
          value: 100,
        },
        active: {
          color: 'green',
          value: 100,
        },
        'Other Contact': {
          color: 'grey',
          value: 100,
        },
        other: {
          color: 'grey',
          value: 100,
        },
      },
      clientTypeText: {
        corporation: 'Corporation',
        foundation: 'Foundation',
        person: 'Individual',
        trust: 'Trust',
        ipp: 'IPP',
        nonprofit: 'Non-Profit',
      },
      fieldsInEditMode: [],
      preferredPhoneNoLocalClone: _.cloneDeep(this.preferredPhoneNo),
      editedClient: _.cloneDeep(this.activeClient),
      phoneValid: false,
      emailValid: false,
      pmTeamName: 'Not Available',
      pmName: 'Not Available',
      v,
      clientStatus: CLIENTSTATUS,
      emailChangeModal: false,
    };
  },
  async mounted() {
    this.preferredPhoneNoLocalClone = this.preferredPhoneNo && this.preferredPhoneNo.length ? this.preferredPhoneNo.slice(0) : '';
    await this.getPMTeamName();
    await this.getPm();
  },
  computed: {
    ...mapState({
      chipColor: (state) => state.app.themeVars['primary-translucent-2'],
      chipTextColor: (state) => state.app.themeVars['primary-translucent-7'],
    }),
    ...mapGetters([
      'canEditBasicClientInformation',
      'teamMembers',
      'canEditNotes',
      'hasFeatureFlag',
      'advisorTeams',
      'allPrograms',
      'isClientStatusActive',
    ]),
    pageTitle() {
      if (this.activeClient.client.client_type === 'person') {
        return this.clientInfo.display_name ? this.clientInfo.display_name : `${this.activeClient.person.first_name} ${this.activeClient.person.last_name}`;
      }
      return this.clientInfo.display_name ? this.clientInfo.display_name : `${this.activeClient.person.name}`;
    },
    phoneSpan() {
      return `(${this.preferredPhoneNoLocalClone.slice(0, 3)}) ${this.preferredPhoneNoLocalClone.slice(3, 6)} - ${this.preferredPhoneNoLocalClone.slice(6, 10)}`;
    },
    workAddressLine() {
      return (this.workAddress ? `${this.workAddress.city}, ${this.workAddress.province}` : 'No work address on record.');
    },
    clientType() {
      return this.activeClient.client.client_type;
    },
    employmentLine() {
      if (this.activeClient.client.client_type !== 'person') return '';
      let msg = 'We don\'t have employment information.';
      if (this.employment) {
        this.employment.occupation || this.employment.employer
          ? msg = `${this.employment.occupation ? this.employment.occupation : 'No occupation on record'} @ ${this.employment.employer ? this.employment.employer : 'No employer on record'}`
          : null;
        this.employment.employment_status == 4 ? msg = `${this.pageTitle} is retired` : null;
        this.employment.employment_status == 3 ? msg = `${this.pageTitle} is unemployed` : null;
      }
      return msg;
    },
    clientInfo() {
      return this.activeClient.client;
    },
    advisorTeam() {
      return this.advisorTeams.find((team) => team.value === this.clientInfo.team).text;
    },
    programName() {
      return this.allPrograms.find((program) => program.value === this.clientInfo.program).text;
    },
    serviceModelName() {
      const serviceModel = this.activeClient?.suitabilityScore?.service_model || '';

      if (serviceModel === '') return '';

      return this.$t(`options:service_model.${ serviceModel }`);
    },
    preferredPhoneNo() {
      if (this.activeClient.client.preferred_number === 'H' && this.clientInfo.home_number) return this.clientInfo.home_number.replace(/-/g, '');
      if (this.activeClient.client.preferred_number === 'M' && this.clientInfo.mobile_number) return this.clientInfo.mobile_number.replace(/-/g, '');
      if (this.activeClient.client.preferred_number === 'O' && this.clientInfo.office_number) return this.clientInfo.office_number.replace(/-/g, '');
      return '';
    },
    preferredPhoneNoLabel() {
      if (this.activeClient.client.preferred_number === 'H' && this.clientInfo.home_number) return 'P:';
      if (this.activeClient.client.preferred_number === 'M' && this.clientInfo.mobile_number) return 'M:';
      if (this.activeClient.client.preferred_number === 'O' && this.clientInfo.office_number) return 'P:';
      return '';
    },
    registeredOnIP() {
      return this.hasFeatureFlag('investor_portal') && this.activeClient.IPAccountStatus;
    },
    hasEmailChangeRequestPending() {
      const PENDING_STATUS = 1;
      return (
        this.registeredOnIP
        && this.activeClient.emailChangeRequestStatus
        && this.activeClient.emailChangeRequestStatus.status === PENDING_STATUS
      );
    },
  },
  methods: {
    getAdvisorName() {
      const a = this.teamMembers.filter((advisor) => advisor.id === this.clientInfo.advisor_id);
      if (a.length) {
        return `${a[0].first_name} ${a[0].last_name}`;
      }
      return 'Not Available';
    },
    async getPMTeamName() {
      if (!this.clientInfo.pm_team) return;
      const response = await fetchTeamById(this.clientInfo.pm_team);
      this.pmTeamName = response.name;
    },
    async getPm() {
      if (!this.clientInfo.pm_id) return;
      const response = await fetchAdvisor(this.clientInfo.pm_id);
      this.pmName = `${response.first_name} ${response.last_name}`;
    },
    launchPhone() {
      if (this.preferredPhoneNoLocalClone) {
        document.location.href = `tel:${this.preferredPhoneNoLocalClone}`;
      }
    },
    launchEmail() {
      if (this.editedClient.client.email) {
        document.location.href = `mailto:${this.editedClient.client.email}`;
      }
    },
    saveNotes() {
      this.saveEdits('notes');
    },
    savePhone() {
      this.saveEdits('phone');
    },
    saveEmail() {
      if (this.registeredOnIP) {
        if (this.editedClient.client.email !== this.activeClient.client.email) {
          this.emailChangeModal = true;
          return;
        }
      }
      this.saveEdits('email');
    },
    toggleEmailEdit() {
      if (!this.registeredOnIP || !this.hasEmailChangeRequestPending || this.fieldsInEditMode.includes('email')) {
        // toggle as normal when there's no pending request
        // otherwise keep email in read-only mode
        this.toggleEditMode('email');
      }
    },
    closeEmailChangeModal() {
      this.emailChangeModal = false;
      // email won't be changed until client verifies new email
      this.editedClient.client.email = this.activeClient.client.email;
      if (this.fieldsInEditMode.includes('email')) {
        this.toggleEditMode('email');
      }
    },
    toggleEditMode(field) {
      if (!this.canEditBasicClientInformation) return;
      const that = this;
      if (this.fieldsInEditMode.includes(field)) {
        this.fieldsInEditMode = this.fieldsInEditMode.filter((f) => f !== field);
      } else {
        this.fieldsInEditMode.push(field);
        // this.$nextTick(this.$refs.emailEditField.focus)
        setTimeout(() => {
          if (that.$refs[`${field}Form`]) that.$refs[`${field}EditField`].focus();
        }, 300);
      }
    },
    togglePhoneNumber() {
      const isClientMaintenanceEnabled = this.hasFeatureFlag('client_maintenance');
      if (isClientMaintenanceEnabled && this.isClientStatusActive) return;

      this.toggleEditMode('phone');
    },
    validate() {
      if (this.$refs.phoneForm && !(this.$refs.phoneForm.validate())) return false;
      if (this.$refs.emailForm && !(this.$refs.emailForm.validate())) return false;
      return true;
    },
    saveEdits(field) {
      // validations
      if (!(this.validate())) return;
      // do the saving
      let numberKey = '';
      switch (this.activeClient.client.preferred_number) {
        case 'H':
          numberKey = 'home_number';
          break;
        case 'M':
          numberKey = 'mobile_number';
          break;
        case 'O':
          numberKey = 'office_number';
          break;
      }
      // numberKey="mobile_number" //hardcoding for demo

      const data = {
        [numberKey]: this.preferredPhoneNoLocalClone,
        email: this.editedClient.client.email,
        quick_notes: this.editedClient.client.quick_notes,
        id: this.activeClient.client.id,
        client_type: this.activeClient.client.client_type,
      };
      this.$store.dispatch('updateClient', data)
        .catch((err) => console.log('err', err));
      // exit edit mode
      this.toggleEditMode(field);
    },
  },
  watch: {
    preferredPhoneNo() {
      this.preferredPhoneNoLocalClone = this.preferredPhoneNo;
    },
  },

};
</script>

<style lang="scss">
@import '~Styles/variables';

.employment-line {
  text-transform: capitalize;
}

.email-fab-flex {
  width: 70px !important;
  max-width: 70px !important;
}
.phone-fab-flex {
  width: 70px !important;
  max-width: 70px !important;
}
.quick-notes {
  width: 100% !important;
  max-height: 90px !important;
  height: 75px !important;
  overflow: display !important;
}
.quick-notes .v-input__slot {
  border-color: rgba(0,0,0,0.2) !important;
  border-width: 1px !important;
  height: 75px !important;
  font-size: 13px !important;
}
.quick-notes .v-input__slot {
  &:hover, &:focus-within, &:focus, &:active {
    border-color: var(--primary-color) !important;
  }
}
.quick-notes.v-text-field--outline{
  .v-input__control>.v-input__slot{
    background: white !important;
  }
}
.inline-edit-field {
  max-width: 285px !important;
  min-width: 185px !important;
}
.inline-edit-field.phone-edit{
  min-width: 162px !important;
}
.quick-notes-display {
  display: -webkit-box;
  height: 4em !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  text-align: justify;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
#display-name {
  margin-bottom: 0px !important;
}
#phone-fab i, #email-fab i {
  color: white !important;
}
#phone-fab, #email-fab {
  background-color: var(--primary-color) !important;
}
div.quick-notes {
  max-height: 3em;
  overflow: hidden;
  line-height: 1.5em;
  text-align: left;
  position: relative;
}
.inline-edit-field.input-field .v-input__slot input{
  margin: 5px 0 0 -5px !important;
  min-height: 32px !important;
  background-color: white !important;
  padding-left: 10px !important;
  border: 1px solid rgba(0,0,0,0.2);
  border-radius: 4px;
  &:hover, &:focus-within, &:focus, &:active {
    border-color: var(--primary-color) !important;
  }

}
.phone-label-prefix {
  font-weight: bold !important;
}

.phone-fab-flex:hover, .email-fab-flex:hover {
  & + .flex {
    span {
      color: var(--primary-color) !important;
    }
  }
  cursor: pointer;
}
.quick-notes-display {
  cursor: pointer !important;
}

</style>
