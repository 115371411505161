<template>
  <div>
    <ClientsSearch class="mt-3" />
    <ClientsListTable />
  </div>
</template>

<script>
import ClientsListTable from './ClientsListTable';
import ClientsSearch from './ClientsSearch';

export default {
  name: 'ClientsTab',
  components: {
    ClientsListTable,
    ClientsSearch,
  },
  created() {
  },
  data() {
    return {
      placeholder: [],
    };
  },
};

</script>
