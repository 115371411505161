<template>
  <v-dialog
    id="fundingDetailsModalId"
    :value="isOpen"
    max-height="50rem"
    max-width="50rem"
  >
    <v-card class="pa-5" max-height="50rem" max-width="50rem">
      <PasFab class="close-btn">
        <v-icon @click="$emit('close')" class="close-icon">close</v-icon>
      </PasFab>
      <PasPageTitle class="mb-3">{{ detailsTitle }}</PasPageTitle>
      <v-layout wrap>
        <v-flex align-items-center xs2>
          <div class="strong-text my-2">For</div>
          <div class="strong-text my-2" v-if=this.transaction.amount>Amount</div>
          <div class="strong-text my-2">From</div>
          <div class="strong-text my-2">To</div>
          <div class="strong-text my-2">Created</div>
          <div class="strong-text my-2">Notes</div>
        </v-flex>
        <v-flex xs10>
          <div class="data-text my-2">{{ clientName }}</div>
          <div class="data-text my-2" v-if=this.transaction.amount>${{ transactionAmount }}</div>
          <div class="data-text my-2">{{ fromAccount }}</div>
          <div class="data-text my-2">{{ toAccount }}</div>
          <div class="data-text my-2">{{ createdAtDate }}</div>
          <div class="data-text my-2">{{ notes }}</div>
        </v-flex>
      </v-layout>
    </v-card>
  </v-dialog>
</template>

<script>
/* eslint-disable vue/max-len */
import _ from 'lodash';
import { REQUEST_TYPES, REQUEST_SUBTYPE_IDS } from '../../../../services/helpers/funding/index';

export default {
  name: 'FundingDetails',
  data() {
    return {
      value: this.isOpen,
    };
  },
  props: {
    isOpen: { type: Boolean },
    transaction: {
      type: Object,
      required: true,
    },
  },
  computed: {
    clientName() {
      return this.$store.state.workflow.selectedWorkflow.clientInfo?.display_name;
    },
    createdAtDate() {
      return this.transaction?.created_at?.slice(0, 10);
    },
    toAccount() {
      // add more conditions when we add more transaction types to funding module
      if (this.transaction.type === 1) { // deposit
        return `${this.transaction.investment_account.nickname}-${this.transaction.investment_account.account_no_dataphile}`;
      } if (this.transaction.type === 2) { // withdrawal
        return `Bank: ${this.transaction.bank_account.account_no}`;
      }
      const toAccount = this.transaction.to_bank_account_info
        || this.transaction.to_investment_account_info
        || this.transaction.to_account_info;
      if (toAccount) {
        return `"${toAccount.account_name}" ${toAccount.type || ''} ${toAccount.account_no || ''}`;
      }
      if (!toAccount && this.transaction.external_institution) { // when request type is External Transfer
        if (this.transaction.subtype?.id === REQUEST_SUBTYPE_IDS.EXTERNAL_TRANSFER_DONATION_REQUEST) {
          return `"${this.transaction.organization_name}" ${this.transaction.external_institution_account_no}`;
        }
        // External Transfer
        const investmentAccountInfo = this.transaction.investment_account_info;
        return `"${investmentAccountInfo.account_name}" ${investmentAccountInfo.type || ''} ${investmentAccountInfo.account_no || ''}`;
      }

      return '--';
    },
    fromAccount() {
      // add more conditions when we add more transaction types to funding module
      if (this.transaction.type === 1) { // deposit
        return `Bank: ${this.transaction.bank_account.account_no}`;
      }
      if (this.transaction.type === 2) { // withdrawal
        return `${this.transaction.investment_account.nickname}-${this.transaction.investment_account.account_no_dataphile}`;
      }
      if (this.transaction.subtype?.id === REQUEST_SUBTYPE_IDS.EXTERNAL_TRANSFER_IN) {
        return `"${this.transaction.external_institution}" ${this.transaction.external_institution_account_no}`;
      }
      const fromAccount = this.transaction.from_bank_account_info
        || this.transaction.from_investment_account_info
        || this.transaction.investment_account_info
        || this.transaction.from_account_info;
      if (fromAccount) {
        return `"${fromAccount.account_name}" ${fromAccount.type || ''} ${fromAccount.account_no || ''}`;
      }
      return '--';
    },
    transactionType() {
      if (this.transaction.subtype) {
        // This is a complex funding request so, getting the type is a little different
        const type = _.startCase(
          _.toLower(
            Object.keys(REQUEST_TYPES).find(
              (key) => REQUEST_TYPES[key].toString() === this.transaction.subtype.type,
            ),
          ),
        );
        return `${this.transaction.subtype.subtype} ${type}`;
      }
      // add more conditions when we add more transaction types to funding module
      if (this.transaction.type === 1) {
        return 'Deposit';
      } if (this.transaction.type === 2) {
        return 'Withdrawal';
      }
      return 'Transaction';
    },
    transactionAmount() {
      const amount = this.transaction.amount || this.transaction.amount_estimated || this.transaction.amount_cash;
      return Math.abs(amount);
    },
    detailsTitle() {
      return `${this.transactionAmount ? `$${this.transactionAmount}` : ''} ${this.transaction.frequency_verbose || ''} ${this.transactionType}`;
    },
    notes() {
      return this.transaction?.notes || '--';
    },
  },
};
</script>
<style scoped>
.data-text {
  font-size: 14px;
}
</style>
