<template>
  <PContainer class="mt-5">
    <ThreeColumn>
      <template #left />
      <template #center>
        <div class="header">
          <div class="icon-wrapper" @click="back" v-on:keydown="back">
            <font-awesome-icon class="icon" :icon="['fal', 'chevron-left']" />
          </div>
          <h3> What kind of {{ selectedRequestType.type }} </h3>
        </div>
        <div class="subtype-container">
          <PCard class="mt-4 mb-9">
            <PFormRadioGroup
              id="funding-subtype-radio-btn"
              :options="fundingSubtypeOptions"
              v-model="subtypeInputValue"
              :label="selectedRequestType.label"
              buttons
              :stacked="true"
              button-variant="outline-primary"
              class="mt-2"
              @change="submit" />
          </PCard>
        </div>
      </template>
      <template #right />
    </ThreeColumn>
  </PContainer>
</template>

<script>
import { mapGetters } from 'vuex';
import { isClientOfTypePerson } from '../../../helpers';
import {
  INVESTMENT_ACCT_BY_TYPE, REQUEST_SUBTYPE_IDS, REQUEST_TYPES, isDefaultSubtype,
} from '../../../services/helpers/funding/index';

export default {
  name: 'FundingRequestTypes',
  data() {
    return {
      subtypeInputValue: null,
    };
  },
  computed: {
    ...mapGetters([
      'activeClient',
      'fundingSubtypes',
      'selectedRequestType',
      'stagedExternalTransfers',
      'legalEntityTransfersFeatureFlag',
    ]),
    fundingSubtypeOptions() {
      if (this.fundingSubtypes.length) {
        return this.fundingSubtypes.map((e) => ({ value: e.id, text: e.subtype }));
      }
      return [];
    },
    clientId() {
      return this.$route.params.clientID;
    },
  },
  methods: {
    async submit(value) {
      let selectedRequestSubtypeObj = this.fundingSubtypes.filter((e) => e.id === value)[0];
      selectedRequestSubtypeObj = {
        ...selectedRequestSubtypeObj,
        name: selectedRequestSubtypeObj.subtype,
      };

      await this.populateStore(selectedRequestSubtypeObj);
      if (this.navigateToAddedTransfers(selectedRequestSubtypeObj)) {
        this.$router.push({ path: `/clients/${this.clientId}/funding-v2/added-transfers` });
      } else {
        this.$router.push({ path: `/clients/${this.clientId}/funding-v2/${this.selectedRequestType.type}` });
      }
    },
    back() {
      this.$store.dispatch('setSelectedRequestType', {});
      this.$store.dispatch('setFundingSubtypes', []);
      this.$store.dispatch('setActiveRequest', {});
      this.$router.go(-1);
    },
    navigateToAddedTransfers(requestSubtype) {
      return (isClientOfTypePerson(this.activeClient.client) || this.legalEntityTransfersFeatureFlag)
        && requestSubtype.id === REQUEST_SUBTYPE_IDS.EXTERNAL_TRANSFER_IN
        && this.stagedExternalTransfers?.length > 0;
    },
    async populateStore(subtype) {
      const clientId = this.clientId;
      if (!isDefaultSubtype(subtype, REQUEST_TYPES.WITHDRAWAL)) {
        // For all non default withdrawal subtypes override the withdrawal types / reasons
        await this.$store.dispatch('setWithdrawalTypes', subtype.id);
      }

      switch (subtype.id) {
        case REQUEST_SUBTYPE_IDS.INTERNAL_TRANSFER_MARGIN_DRAW_DOWN:
          this.$store.dispatch('setInvestmentAccountByType', { clientId, type: INVESTMENT_ACCT_BY_TYPE.MARGIN_DRAW_DOWN });
          break;
        case REQUEST_SUBTYPE_IDS.INTERNAL_TRANSFER_ACCOUNT_ROLLOVER:
          this.$store.dispatch('setInvestmentAccountByType', { clientId, type: INVESTMENT_ACCT_BY_TYPE.ROLLOVER_INCOME });
          this.$store.dispatch('setInvestmentAccountByType', { clientId, type: INVESTMENT_ACCT_BY_TYPE.ROLLOVER_SAVINGS });
          break;
        case REQUEST_SUBTYPE_IDS.INTERNAL_TRANSFER_DIFFERENT_CLIENTS:
          this.$store.dispatch('setInvestmentAccountByType', { clientId, type: INVESTMENT_ACCT_BY_TYPE.DIFFERENT_CLIENTS });
          break;
        case REQUEST_SUBTYPE_IDS.WITHDRAWAL_HBP_LLP_EXCESS:
          this.$store.dispatch('setInvestmentAccountByType', { clientId, type: INVESTMENT_ACCT_BY_TYPE.HBP_LLP });
          break;
        case REQUEST_SUBTYPE_IDS.WITHDRAWAL_RESP:
          this.$store.dispatch('setInvestmentAccountByType', { clientId, type: INVESTMENT_ACCT_BY_TYPE.RESP });
          this.$store.dispatch(
            'setFilteredToInvestmentAccounts',
            {
              clientId,
              types: [INVESTMENT_ACCT_BY_TYPE.CASH, INVESTMENT_ACCT_BY_TYPE.MARGIN_DRAW_DOWN],
            },
          );
          break;
        case REQUEST_SUBTYPE_IDS.WITHDRAWAL_ONE_TIME_RRIF_SRRIF_LIF:
          this.$store.dispatch('setInvestmentAccountByType', { clientId, type: INVESTMENT_ACCT_BY_TYPE.RRIF_SRRIF_LIF });
          this.$store.dispatch(
            'setFilteredToInvestmentAccounts',
            {
              clientId,
              types: [
                INVESTMENT_ACCT_BY_TYPE.CASH,
                INVESTMENT_ACCT_BY_TYPE.MARGIN_DRAW_DOWN,
                INVESTMENT_ACCT_BY_TYPE.TFSA],
            },
          );
          break;
        case REQUEST_SUBTYPE_IDS.EXTERNAL_TRANSFER_IN:
          await this.$store.dispatch('fetchRelinquishingInstitutions');
          if (isClientOfTypePerson(this.activeClient.client) || this.legalEntityTransfersFeatureFlag) {
            await this.$store.dispatch('setStagedExternalTransfers', { clientId });
          }
          break;
        default:
          this.$store.dispatch('setInvestmentAccountByType', { clientId });
          break;
      }

      this.$store.dispatch('setSelectedRequestSubtype', subtype);
      this.$store.dispatch('setPaymentMethods', subtype.id);
    },
  },
};
</script>

<style lang="scss">
.header {
  display: flex;

  h3 {
    margin-left: 12px;
  }
}

.icon {
  size: 14px;
}

.icon-wrapper {
  height: 24px;
  width: 24px;
  text-align: center;
}

.subtype-container {

  p,
  .btn-group-toggle label.btn {
    font-size: 14px !important;
    font-weight: 600;
  }

  .btn-group-toggle label.btn {
    color: #657381;
    background: #FFF;
    height: 48px;
    padding: 12px 16px 12px 16px;
    border: 1px solid #dee2e6;
  }
}
</style>
