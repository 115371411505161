export const generateFilterLabel = (selectedItems, allItems, label) => {
  if (selectedItems?.length >= 1) {
    let itemName = allItems.find(
      (item) => item.value === selectedItems[0],
    ).text;
    if (itemName.length > 10) {
      itemName = `${itemName.substring(0, 10)}...`;
    }
    if (selectedItems.length > 1) {
      return `${label}: ${itemName} + ${selectedItems.length - 1}`;
    }
    return `${label}: ${itemName}`;
  }
  return `${label}: All`;
};
