<template>
  <div class="modal-wrapper">
    <PModal
      :hide-header="true"
      :visible="!!(currentFlow && currentStage && currentModal)"
      centered
      no-close-on-backdrop
    >
      <component
        :is="currentModal"
        v-bind="$attrs"
        v-on="$listeners"
        @closeModal="closeModal"
        class="modal-wrapper__content"
        :featureFlags="features"
      />
    </PModal>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';

const { mapGetters, mapActions } = createNamespacedHelpers('modalManager');

export default {
  name: 'ModalWrapper',
  components: {
  },
  computed: {
    ...mapGetters([
      'currentModal',
      'currentFlow',
      'currentStage',
    ]),
    features() {
      return this.$store.state.app.features;
    },
  },
  data() {
    return {
    };
  },
  methods: {
    ...mapActions([
      'closeModal',
    ]),
    handleKeydown(event) {
      if (event.key === 'Escape' || event.key === 'Esc') {
        this.closeModal();
      }
    }
  },
  mounted() {
    document.addEventListener('keydown', this.handleKeydown);
  },
  beforeDestroy() {
    document.removeEventListener('keydown', this.handleKeydown);
  }
};
</script>

<style scoped>
.modal-wrapper__content {
  padding:16px 0px !important;
}

</style>
