<template>
  <PDropdown
    :text="dropdownLabel"
    size="lg"
    class="dropdown-filter px-2"
  >
    <div v-if="loading" class="text-center">
      <PSpinner />

    </div>
    <PFormCheckboxGroup
      v-else-if="checkboxOptions.length"
      class="px-2"
      :options="checkboxOptions"
      v-model="selectedValues"
    />
    <div v-else class="text-center">
      <p>No options available</p>
    </div>
  </PDropdown>
</template>

<script>
export default {
  name: 'DropdownFilter',
  props: {
    dropdownLabel: {
      type: String,
      default: '',
    },
    checkboxOptions: {
      type: Array,
      default: () => [],
    },
    value: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedValues: this.value,
    };
  },
  watch: {
    value: {
      deep: true,
      handler(newValue) {
        this.selectedValues = newValue;
      },
    },
    selectedValues: {
      deep: true,
      handler(newValue) {
        this.$emit('change', newValue);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.dropdown-filter:deep {

  .dropdown-menu.show {
    max-height: 600px;
    width: 240px;
    overflow-y: auto;
    overflow-x: hidden;
  }
  .custom-checkbox {
    padding: 8px 0px 8px 24px;
  }
  label {
    color: var(--primary, #2e3338);

    /* Small body */
    font-family: "Work Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
}
</style>
