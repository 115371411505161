<template>
  <div>
    <v-form
      ref="form"
      lazy-validation
      @submit.prevent
    >
      <v-layout wrap col class="px-2">
        <v-flex xs3 pb-4>
          <div class="strong-text my-3 pl-2">Investment Mandate</div>
          <PasAutocomplete
            :items="portfolioCategoryItems"
            v-model="newPortfolioCategory"
            :rules="[v.isRequired(), IsAssigned]"
            :readonly="!editable"
          />
        </v-flex>
      </v-layout>
      <PasCircularLoader v-if="processingAccounts && tempInvestmentAccounts.length"/>
      <template v-if="!processingAccounts && tempInvestmentAccounts.length">
        <PasPageSubtitle class="px-2 py-4">Account Portfolio Assignment</PasPageSubtitle>
        <v-layout wrap class="px-2">
          <v-flex xs3>
            <div class="strong-text mb-3">Account Holder Name</div>
          </v-flex>
          <v-flex xs2>
            <div class="strong-text mb-3">Account Type</div>
          </v-flex>
          <v-flex xs2>
            <div class="strong-text mb-3">Account Number</div>
          </v-flex>
          <v-flex xs2 v-if="fieldsToDisplay.includes('subadvisor')">
            <div class="strong-text mb-3">Sub-Advisor</div>
          </v-flex>
          <v-flex xs3 v-if="fieldsToDisplay.includes('model_portfolio')">
            <div class="strong-text mb-3">Model Portfolio</div>
          </v-flex>
        </v-layout>
        <template>
          <v-layout
            v-for="(account, i) in tempInvestmentAccounts"
            :key="i"
            xs12 md4
            px-2 py-4 mb-3row wrap
          >
            <v-flex xs3>
              <div class="mb-3">{{account.clients.join(' & ')}}</div>
            </v-flex>
            <v-flex xs2>
              <div class="mb-3">{{ACCOUNTTYPE[account.account_type_short]}}</div>
            </v-flex>
            <v-flex xs2>
              <div class="mb-3">{{account.account_no_dataphile || ' - '}}</div>
            </v-flex>
            <v-flex
              xs2
              pr-2
              v-if="fieldsToDisplay.includes('subadvisor')"
              :key="i + keyCount"
            >
              <PasAutocomplete
                :items="subadvisorItems"
                v-model="tempInvestmentAccounts[i].subadvisor"
                :rules="[v.isRequired()]"
                :readonly="!editable"
                @change="keyCount++"
              />
            </v-flex>
            <v-flex
              xs3
              v-if="fieldsToDisplay.includes('model_portfolio')"
            >
              <PasAutocomplete
                :items="modelPortfolioItems[account.subadvisor]"
                v-model="tempInvestmentAccounts[i].modelPortfolio"
                :rules="[v.isRequired()]"
                :readonly="!editable"
              />
            </v-flex>
          </v-layout>
        </template>
      </template>
      <PasButton
        class="primary-btn wide-btn mt-3 ml-1"
        @click="save"
        :processing="processingPortfolio"
        v-if="editable && saveBtn"
      >Save</PasButton>
      <PasButton
        v-if="canEditGenerateIps && hasIps"
        class="primary-btn wide-btn mt-3 ml-1"
        @click="launchIPS"
      >Launch IPS</PasButton>
      <v-dialog v-model="modalOpen" :click:outside="close" max-height="60rem" max-width="60rem">
          <v-card class="pa-2" max-height="60rem" max-width="60rem">
            <template>
              <div class="text-xs-center subtitle bold my-4">
                There is currently an IPS workflow already in progress. Would you like to cancel the current workflow and create a new one?
              </div>
            </template>
            <v-layout my-3>
            <v-flex text-xs-center>
              <PasButton
                class="inverted-btn wider-btn px-3 mr-3"
                @click="closeModal"
              >
                Continue with current workflow
              </PasButton>
              <PasButton
                class="primary-btn wider-btn"
                @click="newIPSWorkflow"
              >
                Cancel and relaunch
              </PasButton>
            </v-flex>
            </v-layout>
          </v-card>
      </v-dialog>
    </v-form>
  </div>
</template>

<script>
import _ from 'lodash';
import v from 'Services/validation';
import { mapGetters } from 'vuex';
import { ACCOUNTTYPE } from 'Services/constants';
import {
  createClientPortfolio,
  updateClientPortfolio,
  updateAccountPortfolio,
  fetchFieldsToDisplay,
} from 'Services/api/PortfolioAssignment';
import { modal } from 'Services/mixins';
import { createIpsWorkflow } from 'Services/api/Workflows';
import acApi from 'Services/api';

export default {
  name: 'PortfolioAssignmentTab',
  mixins: [modal],
  props: {
    editable: {
      type: Boolean,
      default: true,
    },
    saveBtn: {
      type: Boolean,
      default: true,
    },
    clientPortfolio: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      processingAccounts: true,
      processingPortfolio: false,
      v,
      newPortfolioCategory: null,
      ACCOUNTTYPE,
      tempInvestmentAccounts: [],
      keyCount: 0,
      modalOpen: false,
      fieldsToDisplay: [],
    };
  },
  computed: {
    ...mapGetters([
      'allPortfolioCategories',
      'allSubadvisors',
      'allModelPortfolios',
      'clientId',
      'canEditGenerateIps',
      'hasFeatureFlag',
    ]),
    activeClient() {
      return this.$store.state.clients.activeClient;
    },
    investmentAccounts() {
      return this.activeClient.investment_accounts;
    },
    hasIps() {
      return this.hasFeatureFlag('ips');
    },
    IsAssigned() {
      if (!this.allPortfolioCategories.length) return true;
      const [unassigned] = this.allPortfolioCategories.filter((pc) => pc.name === 'Unassigned');
      return (this.newPortfolioCategory !== unassigned.id) || 'Please assign a portfolio';
    },
    portfolioCategoryItems() {
      const portfolioCategories = this.allPortfolioCategories;
      const [unassigned] = this.allPortfolioCategories.filter((pc) => pc.name === 'Unassigned');
      if (this.clientPortfolio?.portfolio_category && this.clientPortfolio?.portfolio_category !== unassigned.id) {
        const [currentPortfolio] = this.allPortfolioCategories.filter((pc) => pc.id === this.clientPortfolio.portfolio_category);
      }
      return portfolioCategories.filter((pc) => pc.name !== 'Unassigned').map((pc) => ({ text: pc.name, value: pc.id }));
    },
    portfolioCategoryItemsUpdated() {
      const [unassigned] = this.allPortfolioCategories.filter((pc) => pc.name === 'Unassigned');
      if (this.newPortfolioCategory && this.newPortfolioCategory !== unassigned.id) {
        const [currentPortfolio] = this.allPortfolioCategories.filter((pc) => pc.id === this.newPortfolioCategory);
        return this.allPortfolioCategories.map((pc) => ({ text: pc.name, value: pc.id }));
      }
      return [];
    },
    subadvisorItems() {
      return this.allSubadvisors.map((pc) => ({ text: pc.name, value: pc.id }));
    },
    modelPortfolioItems() {
      let existingModelPortfolios = [];
      if (this.tempInvestmentAccounts.length) {
        existingModelPortfolios = this.tempInvestmentAccounts.map((ia) => ia.model_portfolio);
      }
      const availablePortfolioCategories = this.portfolioCategoryItemsUpdated.map((pc) => pc.value);
      const availableModelPortfolios = this.allModelPortfolios.filter((mp) => availablePortfolioCategories.includes(mp.portfolio_category) || existingModelPortfolios.includes(mp.id));
      const availableMPBySubadvisors = {};
      this.subadvisorItems.forEach((sa) => {
        availableMPBySubadvisors[sa.value] = availableModelPortfolios
          .filter((x) => x.sub_advisor === sa.value)
          .map((pc) => ({ text: pc.name, value: pc.id }));
      });
      return availableMPBySubadvisors;
    },
  },
  methods: {
    openModal() {
      this.modalOpen = true;
    },
    closeModal() {
      this.modalOpen = false;
    },
    async launchIPS() {
      const workflows = this.activeClient.workflows.filter((wf) => wf.workflowType === 'ips_workflow' && wf.workflowStatus === 'in_progress');

      if (!workflows[0]) {
        try {
          await createIpsWorkflow([this.activeClient.client.id], {
            client: this.activeClient.client.id,
          });
          this.$store.dispatch('setSnackbar', { text: 'IPS workflow created', type: 'success' });
          this.$store.dispatch('flipSnackbarSwitch');
          this.createIpsProcessing = false;
          this.$store.dispatch('getWorkflows', this.activeClient.client.id);
        } catch (err) {
          this.createIpsProcessing = false;
          this.snackbarFail();
        }
      } else {
        this.openModal();
      }
    },
    async newIPSWorkflow() {
      const workflows = this.activeClient.workflows.filter((wf) => wf.workflowType === 'ips_workflow');
      const payload = {
        message: 'workflow.ips_workflow.cancel',
        data: {
          workflow_id: workflows[0].id,
        },
      };
      await acApi.post('/advisor_portal/send_message', payload);

      try {
        await createIpsWorkflow([this.activeClient.client.id], {
          client: this.activeClient.client.id,
        });
        this.$store.dispatch('setSnackbar', { text: 'New IPS workflow created', type: 'success' });
        this.$store.dispatch('flipSnackbarSwitch');
        this.createIpsProcessing = false;
        this.$store.dispatch('getWorkflows', this.activeClient.client.id);
      } catch (err) {
        this.createIpsProcessing = false;
        this.snackbarFail();
      }
      this.closeModal();
    },
    async save() {
      if (!this.$refs.form.validate()) return;
      this.processingPortfolio = true;
      try {
        let res;
        const body = {
          client: this.activeClient.client.id,
          portfolio_category: this.newPortfolioCategory,
        };
        if (!this.clientPortfolio?.id) {
          res = await createClientPortfolio(body);
        } else {
          res = await updateClientPortfolio(this.clientPortfolio.id, body);
        }
        this.$emit('portfolio-change', res);

        const updatePromises = this.tempInvestmentAccounts
          .filter((a) => a.modelPortfolio !== a.model_portfolio)
          .map((acc) => updateAccountPortfolio({
            account_id: acc.id,
            subadvisor_id: acc.subadvisor,
            model_portfolio_id: acc.modelPortfolio,
          }));
        await Promise.all(updatePromises);
        await this.$store.dispatch('getInvestmentAccounts', this.activeClient.client.id);
        this.snackBar('success', 'Successfully updated portfolio');
      } catch (err) {
        this.snackBar('fail', err);
      }
      this.processingPortfolio = false;
    },
    async fetchFieldsToDisplay() {
      try {
        const fieldsToDisplay = await fetchFieldsToDisplay('account_portfolio_assignment');
        this.fieldsToDisplay = fieldsToDisplay.map((f) => f.display_field);
      } catch (err) {
        this.$store.dispatch('setSnackbar', { text: 'Error when loading fields to display.', type: 'fail' });
        this.$store.dispatch('flipSnackbarSwitch');
      }
    },
  },
  async mounted() {
    if (!this.allPortfolioCategories.length) await this.$store.dispatch('fetchAllPortfolioCategories');
    if (!this.allSubadvisors.length) await this.$store.dispatch('fetchAllSubAdvisors');
    if (!this.allModelPortfolios.length) await this.$store.dispatch('fetchAllModelPortfolios');

    await this.fetchFieldsToDisplay();

    this.tempInvestmentAccounts = this.investmentAccounts.filter((a) => (a.primary_client_id === this.clientId) && ['O', 'P', 'T'].includes(a.account_status));
    if (!this.tempInvestmentAccounts.length) {
      this.processingAccounts = false;
      return;
    }

    this.tempInvestmentAccounts.forEach(async (a, i) => {
      if (a.model_portfolio) {
        const [curAccModelPorrfolio] = this.allModelPortfolios.filter((mp) => mp.id === a.model_portfolio);
        a.subadvisor = curAccModelPorrfolio.sub_advisor;
        a.modelPortfolio = curAccModelPorrfolio.id;
      } else {
        a.subadvisor = null;
        a.modelPortfolio = null;
      }
      a.clients = a.joint_clients.map((c) => c.display_name);
      a.clients.unshift(a.primary_client.display_name);
      if (i === this.tempInvestmentAccounts.length - 1) {
        this.processingAccounts = false;
      }
    });
  },
  watch: {
    clientPortfolio: {
      immediate: true,
      async handler() {
        if (!_.isEmpty(this.clientPortfolio)) {
          this.newPortfolioCategory = this.clientPortfolio.portfolio_category;
        }
      },
    },
  },
};
</script>
<style scoped>
.wider-btn {
  width:  225px !important;
}
</style>
