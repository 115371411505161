const { formatNumberToDecimal } = require('../number');

export const ROLES = {
  BENEFICIAL_OWNER: {
    name: 'beneficial_owner',
    label: 'Beneficial Owner',
    skipAuthorizedSignatory: true,
    askForPercentageOwnership: true,
  },
  BENEFICIARY: {
    name: 'beneficiary',
    label: 'Beneficiary',
    skipAuthorizedSignatory: true,
  },
  DIRECTOR: {
    name: 'director',
    label: 'Director',
  },
  GUARANTOR: {
    name: 'guarantor',
    label: 'Guarantor',
  },
  OTHER: {
    name: 'other',
    label: 'Other',
  },
  PRESIDENT: {
    name: 'president',
    label: 'President',
  },
  SECRETARY: {
    name: 'secretary',
    label: 'Secretary',
  },
  SETTLOR: {
    name: 'settlor',
    label: 'Settlor',
    skipAuthorizedSignatory: true,
  },
  SPONSORING_FIRM_TRUSTEE: {
    name: 'sponsoring_firm_trustee',
    label: 'Sponsoring Firm Trustee',
  },
  TRADING_AUTHORITY: {
    name: 'trading_authority',
    label: 'Trading Authority',
  },
  TRUSTEE: {
    name: 'trustee',
    label: 'Trustee',
  },
  VICE_PRESIDENT: {
    name: 'vice_president',
    label: 'Vice President',
  },
};

const getFormattedPercentageOwnership = (percentageOwnership) => {
  if (!Number.isNaN(percentageOwnership)) {
    const percentage = formatNumberToDecimal(Number.parseFloat(percentageOwnership) * 100);
    return `${percentage}% Ownership`;
  }
  return undefined;
};

export const getRoleSummary = (corporateRole, options = {
  onlyRoles: true,
  allCanBeOwners: false,
  truncateOnNthRole: undefined,
}) => {
  const {
    onlyRoles,
    truncateOnNthRole,
    allCanBeOwners,
  } = options;
  let role = [];
  if (corporateRole.director) role.push(ROLES.DIRECTOR.label);
  if (corporateRole.guarantor) role.push(ROLES.GUARANTOR.label);
  if (corporateRole.president) role.push(ROLES.PRESIDENT.label);
  if (corporateRole.secretary) role.push(ROLES.SECRETARY.label);
  if (corporateRole.settlor) role.push(ROLES.SETTLOR.label);
  if (corporateRole.sponsoring_firm_trustee) role.push(ROLES.SPONSORING_FIRM_TRUSTEE.label);
  if (corporateRole.trading_authority) role.push(ROLES.TRADING_AUTHORITY.label);
  if (corporateRole.trustee) role.push(ROLES.TRUSTEE.label);
  if (corporateRole.beneficiary) role.push(ROLES.BENEFICIARY.label);
  if (corporateRole.vice_president) role.push(ROLES.VICE_PRESIDENT.label);
  if (corporateRole.beneficial_owner) {
    if (onlyRoles) {
      role.push(ROLES.BENEFICIAL_OWNER.label);
    } else {
      const percentageOwnership = getFormattedPercentageOwnership(
        corporateRole.percentage_ownership,
      );
      role.push(
        `${ROLES.BENEFICIAL_OWNER.label} - ${percentageOwnership}`,
      );
    }
  }
  if (corporateRole.other) {
    if (corporateRole.other_description) {
      role.push(corporateRole.other_description);
    } else {
      role.push(ROLES.OTHER.label);
    }
  }

  if (allCanBeOwners && !onlyRoles && !corporateRole.beneficial_owner) {
    const percentageOwnership = getFormattedPercentageOwnership(
      corporateRole.percentage_ownership,
    );
    role.push(
      `${percentageOwnership}`,
    );
  }

  if (!onlyRoles && corporateRole.authorized_signatory) role.push('Authorized Signatory');
  if (!onlyRoles && corporateRole.preferred_or_mandatory_signor) {
    if (corporateRole.mandatory_signor_additional_info) {
      role.push(`Preferred/Mandatory Signor - ${corporateRole.mandatory_signor_additional_info}`);
    } else {
      role.push('Preferred/Mandatory Signor');
    }
  }

  let truncatedRoles = 0;
  if (truncateOnNthRole && role.length > truncateOnNthRole) {
    truncatedRoles = role.length - truncateOnNthRole;
    role = role.slice(0, truncateOnNthRole);
  }

  return role.join(', ') + (truncatedRoles > 0 ? ` +${truncatedRoles} more` : '');
};

export const LEGAL_ENTITY_TYPES = {
  corporation: 'Corporation',
  foundation: 'Foundation',
  person: 'Individual',
  trust: 'Trust',
  ipp: 'IPP',
  nonprofit: 'Non-Profit',
};

export const isLegalEntityClientType = (clientType) => Object.keys(LEGAL_ENTITY_TYPES)
  .includes(clientType);

export const ROLE_CONFIG = {
  CORPORATION: {
    INDIVIDUAL: [
      ROLES.PRESIDENT,
      ROLES.VICE_PRESIDENT,
      ROLES.DIRECTOR,
      ROLES.SECRETARY,
      ROLES.GUARANTOR,
      ROLES.TRADING_AUTHORITY,
      {
        ...ROLES.BENEFICIAL_OWNER,
      },
      ROLES.OTHER,
    ],
    LEGAL_ENTITY: [
      ROLES.GUARANTOR,
      ROLES.BENEFICIAL_OWNER,
      ROLES.OTHER,
    ],
  },
  FOUNDATION: {
    INDIVIDUAL: [
      ROLES.PRESIDENT,
      ROLES.VICE_PRESIDENT,
      ROLES.DIRECTOR,
      ROLES.SECRETARY,
      ROLES.TRADING_AUTHORITY,
      ROLES.OTHER,
    ],
    LEGAL_ENTITY: [
      ROLES.BENEFICIAL_OWNER,
      ROLES.OTHER,
    ],
  },
  IPP: {
    INDIVIDUAL: [
      ROLES.BENEFICIARY,
      ROLES.TRUSTEE,
      ROLES.SETTLOR,
      ROLES.OTHER,
    ],
    LEGAL_ENTITY: [
      {
        ...ROLES.BENEFICIAL_OWNER,
        disabled: (item) => item.settlor,
      },
      {
        ...ROLES.SETTLOR,
        disabled: (item) => item.beneficial_owner,
      },
      ROLES.OTHER,
    ],
  },
  NONPROFIT: {
    INDIVIDUAL: [
      ROLES.PRESIDENT,
      ROLES.VICE_PRESIDENT,
      ROLES.DIRECTOR,
      ROLES.SECRETARY,
      ROLES.TRADING_AUTHORITY,
      ROLES.OTHER,
    ],
    LEGAL_ENTITY: [
      ROLES.BENEFICIAL_OWNER,
      ROLES.OTHER,
    ],
  },
  TRUST: {
    INDIVIDUAL: [
      ROLES.BENEFICIARY,
      ROLES.SETTLOR,
      ROLES.TRUSTEE,
      ROLES.OTHER,
    ],
    LEGAL_ENTITY: [
      ROLES.BENEFICIARY,
      ROLES.SETTLOR,
      ROLES.TRUSTEE,
      ROLES.OTHER,
    ],
  },
};

export const LEGAL_ENTITY_INSTRUCTIONS = {
  corporation: `Please note, you must add in a Secretary role to open a Corporate account.
  Additionally, ensure you include all individuals that are authorized to provide instructions
  for the Corporation as a Signing Officer and beneficial owners with their percentage of ownership.
  For multi-layered corporations, you will add the related entities and capture the relevant roles within
  their profiles.`,
  foundation: `Please note, you must add in a Secretary role to open a Foundation account.
  Additionally, ensure you include all individuals that are authorized to provide instructions
  for the Foundation as a Signing Officer and beneficial owners with their percentage of ownership
  (if applicable). For multi-layered foundations, you will add the related entities and within their
  profiles capture the relevant roles.`,
  nonprofit: `Please note, you must add in a Secretary role to open a Non-Profit account.
  Additionally, ensure you include all individuals that are authorized to provide instructions
  for the Non-Profit as a Signing Officer and beneficial owners with their percentage of ownership
  (if applicable). For multi-layered non-profits, you will add the related entities and within their
  profiles capture the relevant roles.`,
  trust: `Please note, you must add the Settlors, Trustees and known Beneficiaries.
  Additionally, please ensure you include all of the individuals that are authorized to provide
  instructions for the Trust as a Signing Officer. For multi-layered trusts, you will add the
  related entities and capture the relevant roles within their profiles.`,
  ipp: `Please note, you must add the Settlors, Trustees and Beneficiaries.
  Generally, the plan's sponsoring firm will be deemed the settlor of the account(s).
  Additionally, please ensure you include all of the individuals that are authorized to provide
  instructions for the IPP as a Signing Officer.`,
};
