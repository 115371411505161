<template>
  <div>

    <pas-list
      :columns="columns"
      :itemName="'clients'"
      :items="clients"
      :loading="loading"
      @toggleSort="toggleSort($event)"
    >
      <template slot-scope="{ item }">
        <v-layout fill-height row @click="viewClientProfile(item)">
          <v-flex xs3>
            <v-layout fill-height row align-center justify-start>
              <v-layout ml-3 mr-4 column>
                <v-flex class="strong-text underline-hover" @click.stop="">
                  <router-link :to="`clients/${item.id}`">
                    {{item.display_name}}
                  </router-link>
                </v-flex>
              </v-layout>
            </v-layout>
          </v-flex>
          <v-flex xs2>
            <v-layout fill-height row align-center justify-start>
              <pas-status-bullet
                :color="statusMap[item.client_status] ? statusMap[item.client_status].color : 'grey'"
                :value="statusMap[item.client_status] ? statusMap[item.client_status].value : 0"
              />&nbsp;&nbsp;
              {{clientStatus[item.client_status]}}
            </v-layout>
          </v-flex>
          <v-flex xs3>
            <v-layout fill-height row align-center justify-start>
              <div class="link-text email-cell underline-hover">
                <a :href="`mailto:${item.email}`" @click.stop="">{{item.email}}</a>
              </div>
            </v-layout>
          </v-flex>
          <v-flex xs2>
            <v-layout fill-height row align-center justify-start>
              <div class="link-text mobile-cell underline-hover">
                <a
                  :href="`tel:${item.preferred_number}`"
                  @click.stop=""
                >{{formatPhoneNumber(item.preferred_number)}}</a>
              </div>
            </v-layout>
          </v-flex>
          <v-flex xs1 class="mr-2" v-if="hasFeatureFlag('client_data_hierarchy')">
            <v-layout fill-height row align-center justify-start>
              <div>
                {{item.team_id ? getTeamName(item.team_id) : 'N/A'}}
              </div>
            </v-layout>
          </v-flex>
          <v-flex xs1 class="mr-2" v-else>
            <v-layout fill-height row align-center justify-start>
              <div>
                {{item.rep_code ? item.rep_code : 'N/A'}}
              </div>
            </v-layout>
          </v-flex>
          <v-flex xs1>
            <v-layout align-center fill-height @click.stop="">
              <v-spacer />
              <pas-more-menu
                v-if="canEditMyClientsBasicInformation"
                @click="$event(item)"
                :editOptions="editOptions"
              />
            </v-layout>
          </v-flex>
        </v-layout>
      </template>
    </pas-list>

    <v-dialog
      v-if="canDeleteBasicClientInformation"
      max-width="600px"
      :value="deleteClientModal"
      persistent
    >
      <v-card class="pa-5">
        <PasFab class="close-btn">
          <v-icon @click="deleteClientModal = null">close</v-icon>
        </PasFab>
        <v-card-title class="headline">
          {{deleteClientModal && deleteClientModal.client_status === 'active' ? 'Cannot d' : 'D'}}elete this client
        </v-card-title>
        <v-card-text v-if="deleteClientModal && deleteClientModal.client_status === 'active'">
          Cannot delete active clients.
        </v-card-text>
        <v-card-text v-if="deleteClientModal && deleteClientModal.client_status !== 'active'">
          Are you sure you want to delete the client?
        </v-card-text>
        <v-layout row>
          <v-spacer />
          <pas-button v-if="deleteClientModal && deleteClientModal.client_status !== 'active'" class="primary-btn" @click="deleteClient">Ok</pas-button>
        </v-layout>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>

import { CLIENTSTATUS } from 'Services/constants';
import { getAvatar } from 'Services/helpers/files';
import { mapState, mapGetters } from 'vuex';
import { formatPhoneNumber, editClientRoute } from 'Services/mixins';

export default {
  name: 'clients-list',
  props: {
    clients: Array,
    loading: Boolean,
    sort: Object,
    sortAlphabetically: Boolean,
  },
  mixins: [formatPhoneNumber, editClientRoute],
  data() {
    return {
      baseColumns: [
        {
          header: 'Client Name', id: 'display_name', size: 'xs3', isSortable: true, sortValue: 1,
        },
        {
          header: 'Status', size: 'xs2', isSortable: false,
        },
        {
          header: 'Email', size: 'xs3', isSortable: false,
        },
        { header: 'Phone', size: 'xs2', isSortable: false },
      ],
      statusMap: {
        professional: {
          color: 'yellow',
          value: 100,
        },
        prospect: {
          color: 'green',
          value: 50,
        },
        inactive: {
          color: 'red',
          value: 100,
        },
        active: {
          color: 'green',
          value: 100,
        },
        'Other Contact': {
          color: 'grey',
          value: 100,
        },
      },
      clientStatus: CLIENTSTATUS,
      getAvatar,
      deleteClientModal: null,
    };
  },
  computed: {
    ...mapGetters([
      'canEditMyClientsBasicInformation',
      'canDeleteBasicClientInformation',
      'hasFeatureFlag',
      'advisorTeams',
    ]),
    ...mapState({
      chipColor: (state) => state.app.themeVars['primary-translucent-2'],
      chipTextColor: (state) => state.app.themeVars['primary-translucent-7'],
    }),
    editOptions() {
      const options = [
        {
          title: 'View Profile',
          onClick: (client) => this.$router.push({ path: `clients/${client.id}` }),

        },
        {
          title: 'Edit',
          onClick: (client) => this.$router.push(this.editClientRoute(client)),
        },
      ];
      if (this.canDeleteBasicClientInformation) {
        options.push(
          {
            title: 'Delete',
            onClick: (client) => { this.deleteClientModal = client; },
          },
        );
      }
      return options;
    },
    columns() {
      let columns = this.baseColumns;
      let lastColumn = {
        header: 'Advisor Code', size: 'xs2', isSortable: false,
      };
      if (this.hasFeatureFlag('client_data_hierarchy')) {
        lastColumn = {
          header: 'Advisor Team', size: 'xs2', isSortable: false,
        };
      }
      columns = columns.concat(lastColumn);
      return columns;
    },
  },
  methods: {
    toggleSort(event) {
      for (let i = 0; i < this.columns.length; i++) {
        const col = this.columns[i];
        if (col.header === event.header && event.sortValue) {
          col.sortValue *= -1;
          this.$emit('toggleSort', col);
          break;
        }
      }
    },
    async deleteClient() {
      if (this.deleteClientModal.client_status !== 'active') {
        const client = await this.$store.dispatch('fetchClientByID', this.deleteClientModal.id);
        if (client.client_status !== 'active') {
          try {
            await this.$store.dispatch('deleteClient', this.deleteClientModal.id)
            this.$emit('deleteClientSuccess');
            this.handleResponses('success');
          } catch {
            this.handleResponses('fail');
          }
        }
      }
    },
    handleResponses(type) {
      const text = type === 'fail' ? 'Failed to delete client' : 'Client successfully deleted';
      this.$store.dispatch('setSnackbar', { text, type });
      this.$store.dispatch('flipSnackbarSwitch');
      this.deleteClientModal = null;
    },
    viewClientProfile(item) {
      this.$router.push({ path: `clients/${item.id}` });
    },
    getTeamName(teamID) {
      return this.advisorTeams.find((team) => team.value === teamID).text;
    },
  },
  watch: {
    sortAlphabetically() {
      this.columns[0].sort = this.sortAlphabetically;
    },
  },
};
</script>

<style lang="scss" scoped>

@import '~Styles/variables';

.v-list__tile__content {
  font-size: 14px;
}
.client-item .email-cell {
  display: block;
  width: 90% !important;
  overflow: hidden !important;
}
.clients-list .link-text:not(.client-name) a {
  color: $light-text !important;
  &:hover {
    color: var(--primary-color) !important;
  }
}
.clients-list .link-text.client-name a {
  &:hover {
    color: var(--primary-color) !important;
  }
}

</style>
