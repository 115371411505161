<!-- eslint-disable max-len -->
<template>
  <v-layout column text-xs-center align-center>
    <v-flex class='profile-avatar client-avatar' align-center mb-2>
      <div class="avatar-pos">
        <upload-avatar
          class="avatar-pos"
          :id="'clientAvatar'"
          :reference="'clientAvatar'"
          :type="true"
          @updateDropzone="updateDropzone"
          v-if="avatarRefresh"
          :readonly="readonly"
        >
          <pas-avatar
            :isCorporate="clientType !== 'person'"
            :avatar="getAvatar('clients', activeClient.client.id)"
            :style="{
              position: 'relative', zIndex: '1', width: '90%', height: '90%',
            }"
          />
        </upload-avatar>
        <canvas id="avatarRiskChart" position="absolute" width="100%" height="100%" />
      </div>
    </v-flex>

    <template v-if="clientType === CLIENT.TYPES.person">
      <v-flex v-if="mostRecentOnboardingWorkflow || canEditOpenAccount && riskScore" text-xs-center mt-3>Risk Profile</v-flex>
      <v-flex row text-xs-center>
        <span class="strong-text">{{riskScore ? `${riskLabel} - ${riskScore.toFixed(1)} / 10` : ''}}</span>
        <template v-if="!riskScore && !readonly">

          <!--This section is for displaying "Initiate onboarding" or "Re-Initiate onboarding" -->
          <template v-if="!mostRecentOnboardingWorkflow">

            <!--the value of IPInviteStatus is valid only for registrants that are using Investor Portal -->

            <!--registrants using Investor Portal -->
            <span
              v-if="canEditOpenAccount && !IPInviteStatus && hasNeverBeenInitiated && registrantUsesInvestorPortal"
              class="action-text-lower"
              data-testid="initiate-onboarding-link"
              @click="openAccount"
            >Initiate Onboarding</span>
            <span
              v-if="canEditOpenAccount && IPInviteStatus && hasCancelledWorkflow && registrantUsesInvestorPortal"
              class="action-text-lower"
              @click="openAccount"
            >Re-Initiate Onboarding</span>

            <!--registrants not using Investor Portal -->
            <span
              v-if="canEditOpenAccount && hasNeverBeenInitiated && !registrantUsesInvestorPortal"
              class="action-text-lower"
              data-testid="initiate-onboarding-link"
              @click="openAccount"
            >Initiate Onboarding</span>
            <span
              v-if="canEditOpenAccount && hasCancelledWorkflow && !registrantUsesInvestorPortal"
              class="action-text-lower"
              @click="openAccount"
            >Re-Initiate Onboarding</span>
          </template>
          <template v-else-if="mostRecentOnboardingWorkflow">
            <span
              class="action-text-lower"
              @click="launchSurvey"
            >Start Risk Questionnaire</span>
          </template>
        </template>

        <template v-if="registrantUsesInvestorPortal">
          <div v-if="IPInviteStatus === IP_INVITATION_STATUSES.accepted" class="mt-2">
            Invite Accepted
          </div>
          <div v-if="!IPInviteStatus" class="action-text-lower">
            Unregistered
          </div>
          <router-link
            v-if="canSendInvite"
            :to="`/clients/${$route.params.clientID}/invite`"
            class="action-text-lower underline-hover margin-auto"
          >
            <template v-if="!IPInviteStatus">Send Invite</template>
            <template v-else>Resend Invite</template>
          </router-link>

        </template>
      </v-flex>

      <v-flex v-if="riskScore" text-xs-center>
        <span
          class="action-text-lower"
          @click="linkToRiskScore"
        >View Details</span>
      </v-flex>
    </template>
    <template v-else>
      <v-flex row text-xs-center mt-3>
        <template v-if="registrantUsesInvestorPortal">
          <div v-if="IPInviteStatus === IP_INVITATION_STATUSES.accepted" class="mt-2">
            Invite Accepted
          </div>
          <div v-if="!IPInviteStatus" class="action-text-lower">
            Unregistered
          </div>
          <router-link
            v-if="canSendInvite"
            :to="`/clients/${$route.params.clientID}/invite`"
            class="action-text-lower underline-hover margin-auto"
          >
            <template v-if="!IPInviteStatus">Send Invite</template>
            <template v-else> Resend Invite</template>
          </router-link>
        </template>
      </v-flex>
    </template>
    <template v-if="canUnlockClient">
      <div
        class="action-text-lower"
        @click="linkToUnlock"
      >
        Unlock Account
      </div>
    </template>
    <pas-fullscreen-modal
      :isOpen="launchSurveyScreen"
      @close="closeSurveyScreen"
    >
      <v-container class="accounts-container">
        <trigger-onboarding
          :surveyOptionsLoaded="surveyOptionsLoaded"
          :onboardingSetupLoading="onboardingSetupLoading"
          @triggerInPerson="triggerInPerson"
          @triggerByEmail="triggerByEmail"
        />
      </v-container>
    </pas-fullscreen-modal>
  </v-layout>
</template>

<script>
import Chart from 'chart.js';
import TriggerOnboarding from 'Components/Vue-Routes/MyClients/ClientView/ClientMenu/AddInvestmentAccounts/TriggerOnboarding';
import { getAvatar } from 'Services/helpers/files';
import { launchOnboarding } from 'Services/helpers/onboarding';
import { WORKFLOW, CLIENT, IP_INVITATION_STATUSES } from 'Services/constants';
import { mapState, mapGetters } from 'vuex';
import UploadAvatar from 'Components/Shared/UploadAvatar';
import { fetchNotInProgressWorkflowsForClient } from 'Services/api/Workflows';
import _ from 'lodash';

export default {
  name: 'profile-avatar',
  components: {
    TriggerOnboarding,
    UploadAvatar,
  },
  props: {
    riskScore: Number,
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      IP_INVITATION_STATUSES,
      CLIENT,
      avatarRefresh: true,
      getAvatar,
      launchSurveyScreen: false,
      surveyOptionsLoaded: false,
      onboardingSetupLoading: false,
      hasNeverBeenInitiated: false, // client that has no onboarding in progress AND has never go through an onboarding workflow
      hasCancelledWorkflow: false, // client that has no onboarding in progress AND has onboarding workflow cancelled
    };
  },
  async mounted() {
    const { workflows } = await fetchNotInProgressWorkflowsForClient(this.activeClient.client.id);
    const allWorkflows = [...this.workflows, ...workflows];

    const allOnboardingWorkflows = allWorkflows.filter((x) => x.workflowType.includes('onboarding'));
    const allOnboardingWorkflowsCancelled = allOnboardingWorkflows.filter((x) => x.workflowStatus?.toLowerCase().includes('cancelled'));

    const nbOnboardingWorkflow = allOnboardingWorkflows.length;
    const nbOnboardingWorkflowCancelled = allOnboardingWorkflowsCancelled.length;

    if (nbOnboardingWorkflow == 0) {
      // no onboarding workflow ever started
      this.hasNeverBeenInitiated = true;
    }
    if ((nbOnboardingWorkflow > 0) && (nbOnboardingWorkflow == nbOnboardingWorkflowCancelled)) {
      // all onboarding have been cancelled and none is in_progress
      this.hasCancelledWorkflow = true;
    } else {
      // I have an onboarding in_progress
    }

    while (!document.querySelector('#avatarRiskChart')) {
      await new Promise((r) => setTimeout(r, 100));
    }
    this.initializeAvatarChart();
    // console.log('feat flag ip', this.$store.getters.hasFeatureFlag('investor_portal'))
  },
  computed: {
    ...mapGetters([
      'canEditOpenAccount',
      'hasFeatureFlag',
    ]),
    ...mapState({
      chipColor: (state) => state.app.themeVars['primary-translucent-2'],
      chipTextColor: (state) => state.app.themeVars['primary-translucent-7'],
      activeClient: (state) => state.clients.activeClient,
      primaryColor: (state) => state.app.themeVars.primaryColor,
    }),
    canUnlockClient() {
      return this.hasFeatureFlag('investor_portal') && this.IPAccountStatus && this.IPAccountStatus.account_suspended === true;
    },
    canSendInvite() {
      const investmentAcctRequirement = this.clientType === CLIENT.TYPES.person
        ? !!this.activeClient.investment_accounts.length : true;
      return this.hasFeatureFlag('investor_portal')
      && investmentAcctRequirement
      && !this.completedIpRegistration;
    },
    clientType() {
      return this.activeClient.client.client_type;
    },
    registrantUsesInvestorPortal() {
      // this fetch the value from Vuex to know if the registrant is using Investor Portal
      // this value is also in the table datahub_features
      return this.hasFeatureFlag('investor_portal');
    },
    IPInviteStatus() {
      return this.activeClient.IPInviteStatus;
    },
    IPAccountStatus() {
      return this.activeClient.IPAccountStatus;
    },
    completedIpRegistration() {
      return !!this.activeClient.IPVerificationStatus?.verified_user;
    },
    workflows() {
      return (this.activeClient ? this.activeClient.workflows.map((x) => {
        if (!x.workflowType) {
          x.workflowType = x.type;
        }
        if (!x.workflowStatus) {
          x.workflowStatus = x.status;
        }
        return x;
      }) : []);
    },
    mostRecentOnboardingWorkflow() {
      const sortedFiltered = this.workflows.sort((a, b) => (a.created_at > b.created_at ? -1 : 1))
        .filter((x) => [WORKFLOW.TYPES.client_onboarding, WORKFLOW.TYPES.supplemental].includes(x.workflowType));
      return (sortedFiltered ? sortedFiltered[0] : null);
    },
    riskLabel() {
      if (this.activeClient.riskProfile?.risk_profile_description) {
        return this.capitalizeWords(this.activeClient.riskProfile.risk_profile_description);
      }
      return 'Unknown';
    },
  },
  methods: {
    openModal(modalType) {
      if (modalType === 'newInvestmentAccount') {
        this.$store.commit('OPEN_MODAL', 'add-corporate-investment-accounts');
      }
    },
    openAccount() {
      if (this.clientType === 'person') {
        this.$store.commit('OPEN_MODAL', 'add-investment-accounts');
      } else if (this.clientType === 'corporation') {
        this.$store.commit('OPEN_MODAL', 'add-corporate-investment-accounts');
      }
    },
    async triggerInPerson() {
      if (!this.mostRecentOnboardingWorkflow) return; // should not launch survey engine without an existing workflow
      this.onboardingSetupLoading = true;
      await launchOnboarding([], this.activeClient.client, this.mostRecentOnboardingWorkflow.type, 'inPerson', this.mostRecentOnboardingWorkflow._id);
      this.onboardingSetupLoading = false;
      this.closeSurveyScreen();
    },
    async triggerByEmail() {
      if (!this.mostRecentOnboardingWorkflow) return; // should not launch survey engine without an existing workflow
      this.onboardingSetupLoading = true;
      await launchOnboarding([], this.activeClient.client, WORKFLOW.TYPES.supplemental, 'byEmail', this.workflowId);
      this.onboardingSetupLoading = false;
      this.closeSurveyScreen();
    },
    launchSurvey() {
      this.launchSurveyScreen = true;
      setTimeout(() => {
        this.surveyOptionsLoaded = true;
      }, 250);
    },
    closeSurveyScreen() {
      this.launchSurveyScreen = false;
      this.surveyOptionsLoaded = false;
    },
    linkToRiskScore() {
      this.$router.push(`/clients/${this.$route.params.clientID}/risk-profile`);
    },
    linkToUnlock() {
      this.$router.push(`/clients/${this.$route.params.clientID}/unlock`);
    },
    updateDropzone() {
      this.avatarRefresh = false;
      this.$nextTick().then(() => {
        this.avatarRefresh = true;
      });
    },
    format(s) {
      if (typeof s !== 'string' || s.length === 0) return '';
      s = s.charAt(0).toUpperCase() + s.slice(1);
      return s.replace(new RegExp('_', 'g'), ' ');
    },
    capitalizeWords(s) {
      return s.split('-').map((word) => _.capitalize(word)).join('-');
    },
    initializeAvatarChart() {
      const ctx = document.getElementById('avatarRiskChart').getContext('2d');
      const { clientType } = this;
      Chart.elements.Arc.prototype.draw = function () {
        const vm = this._view;
        const sA = vm.startAngle;
        const eA = vm.endAngle;

        ctx.beginPath();
        ctx.arc(vm.x, vm.y, vm.outerRadius, sA + window.arcSpacing, eA - window.arcSpacing);
        ctx.strokeStyle = vm.backgroundColor;
        ctx.lineWidth = vm.borderWidth;
        ctx.lineCap = 'round';
        ctx.stroke();
        ctx.closePath();
      };
      const rawRiskScores = this.activeClient.riskScores;
      const isEmptyRiskScore = !rawRiskScores.tolerance;
      let backgroundColors;
      let hoverBackgroundColors;
      let borderColors;
      let dataVals;
      let data;
      let labels;
      const riskScores = (({
        required_return, tolerance, capacity, perception, knowledge,
      }) => ({
        required_return, tolerance, capacity, perception, knowledge,
      }))(rawRiskScores);
      const borderColor = '#fff';
      let remainder;
      if (this.activeClient.client.client_type !== CLIENT.TYPES.person) {
        // not a person client, no risk score, display as 100%
        window.arcSpacing = 0.06;

        labels = {
          a: '', b: '', c: '', d: '', e: '',
        };
        dataVals = [1000, 1000, 1000, 1000, 1000];
        data = [...dataVals];
        backgroundColors = [
          this.chipTextColor,
          this.chipTextColor,
          this.chipTextColor,
          this.chipTextColor,
          this.chipTextColor,
        ];
        borderColors = [
          borderColor,
          borderColor,
          borderColor,
          borderColor,
          borderColor,
        ];
        hoverBackgroundColors = [
          this.chipTextColor,
          this.chipTextColor,
          this.chipTextColor,
          this.chipTextColor,
          this.chipTextColor,
        ];
      } else if (isEmptyRiskScore) {
        // initialize chart for empty risk scores
        window.arcSpacing = 0.00;

        labels = [];
        dataVals = [];
        remainder = 100;
        backgroundColors = ['#eee'];
        borderColors = [borderColor];
        hoverBackgroundColors = ['#eee'];
        data = [remainder];
      } else {
        // initialize chart for client with risk score
        window.arcSpacing = 0.06;

        labels = [...Object.keys(riskScores).map((x) => this.format(x)), ''];
        hoverBackgroundColors = [
          this.chipTextColor,
          this.chipTextColor,
          this.chipTextColor,
          this.chipTextColor,
          this.chipTextColor,
          '#eee',
        ];
        backgroundColors = [
          this.chipTextColor,
          this.chipTextColor,
          this.chipTextColor,
          this.chipTextColor,
          this.chipTextColor,
          '#eee',
        ];
        borderColors = [
          borderColor,
          borderColor,
          borderColor,
          borderColor,
          borderColor,
          borderColor,
        ];
        riskScores.perception = (riskScores.perception + 3) * 2;
        dataVals = Object.values(riskScores).map((x) => (x <= 1 ? 1.0 : parseFloat(x)));
        remainder = Math.round((50 - (dataVals.reduce((acc, item) => acc + item, 0))) * 10) / 10;
        data = [...dataVals, remainder];
      }

      const myChart = new Chart(ctx, {
        type: 'doughnut',

        data: {
          labels,
          datasets: [{
            label: '',
            data,
            backgroundColor: backgroundColors,
            hoverBackgroundColor: hoverBackgroundColors,
            borderColor: borderColors,
            borderWidth: 6,
          }],
        },
        options: {
          cutoutPercentage: 80,
          axes: {
            display: false,
          },
          scales: {
            display: false,
          },
          legend: {
            display: false,
          },
          elements: {
            arc: {
              borderWidth: 6,
            },
          },
          tooltips: {
            filter(tooltipItem) {
              if (clientType !== CLIENT.TYPES.person) return false;
              return (!isEmptyRiskScore ? tooltipItem.index !== 5 : tooltipItem.index !== 0);
            },
            callbacks: {
              label(tooltipItem, data) {
                const label = data.labels[tooltipItem.index] || '';
                const value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
                if (label === 'Perception') return (`${label}: ${value / 2 - 3}`);
                return (`${label}: ${value}`);
              },
            },
            enabled: true,
            mode: 'single',
            onlyShowForDatasetIndex: [0, 1],
            backgroundColor: 'white',
            bodyFontColor: 'rgba(0,0,0,0.5)',
            displayColors: false,
          },
        },
      });
    },
  },
};

</script>

<style lang="scss">

.profile-avatar {
  position: relative;
  z-index: 5;
  width:180px;
  height:180px;
}

.risk-chip {
  padding-right: 15px !important;
  padding-left: 15px !important;
  font-family: "AvenirLTPro-Medium", sans-serif !important;
}

#avatarRiskChart {
  z-index: 1;
}

.avatar-pos{
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
