import {
  hasModule, canViewSubmodule, canEditSubmodule, canDeleteSubmodule,
  canAccessUserLevelModule,
} from './permissions/permissionsHelpers';
import { fetchRolesAndPermissionsForUser } from '../../services/api/RolesAndPermissions';
const defaultState = {
  featureFlagsFetched: false,
  featureEnabled: false,
  permissionsFetched: false,
  roles: [],
  modules: new Set(),
  submodules: {},
  userLevelModules: {},
};

const mutations = {
  SET_PERMISSIONS(state, permissions) {
    state.roles = permissions.roles;
    state.modules = permissions.modules;
    state.submodules = permissions.submodules;
    state.userLevelModules = permissions.userLevelModules;
    state.permissionsFetched = true;
  },
  SET_FEATURES(state, features) {
    state.featureFlagsFetched = true;
    state.featureEnabled = features.roles_and_permissions;
  },
};

const actions = {
  setPermissions({ commit }, userId) {
    return fetchRolesAndPermissionsForUser(userId)
      .then((permissions) => {
        commit('SET_PERMISSIONS', permissions);
      });
  },
};

// Define getters for specific features here
// Use the helpers in the permissions helpers folder for an easier time writing rules
export const getters = {
  permissionsFetched: (state) => state.featureFlagsFetched && state.permissionsFetched,
  canAccessFullClientProfile: (state) => hasModule(state, 'view_full_profile'),
  canAccessInsights: (state) => hasModule(state, 'insights'),
  canViewInsight: (state) => canViewSubmodule(state, 'bi_reports'),
  canViewClientDetails: (state) => canViewSubmodule(state, 'client_details'),
  canEditClientDetails: (state) => canEditSubmodule(state, 'client_details'),
  canViewFinancialProfile: (state) => canViewSubmodule(state, 'financial_profile'),
  canEditFinancialProfile: (state) => canEditSubmodule(state, 'financial_profile'),
  canViewRiskProfile: (state) => canViewSubmodule(state, 'risk_profile'),
  canEditRiskProfile: (state) => canEditSubmodule(state, 'risk_profile'),
  canEditNotes: (state) => canEditSubmodule(state, 'client_notes'),
  canViewBasicClientInformation: (state) => canViewSubmodule(state, 'my_clients_basic_information'),
  canEditBasicClientInformation: (state) => canEditSubmodule(state, 'my_clients_basic_information'),
  canDeleteBasicClientInformation: (state) => canDeleteSubmodule(state, 'my_clients_basic_information'),
  canAccessOperationalCapabilities: (state) => hasModule(state, 'operational_capabilities'),
  canEditOpenAccount: (state) => canEditSubmodule(state, 'investment_accounts'),
  canEditBankAccountSetup: (state) => canEditSubmodule(state, 'bank_accounts'),
  canEditTransferAccount: (state) => canEditSubmodule(state, 'account_transfers'),
  canEditGenerateTransferDocuments: (state) => canEditSubmodule(state, 'account_transfers'),
  canEditSetUpSystematic: (state) => canEditSubmodule(state, 'funds_and_systematic_transfers'),
  canEditTransferFunds: (state) => canEditSubmodule(state, 'funds_and_systematic_transfers'),
  canEditGenerateIps: (state) => canEditSubmodule(state, 'generate_ips'),
  canViewInvestmentAccounts: (state) => canViewSubmodule(state, 'investment_accounts'),
  canEditInvestmentAccounts: (state) => canEditSubmodule(state, 'investment_accounts'),
  canDeleteInvestmentAccounts: (state) => canDeleteSubmodule(state, 'investment_accounts'),
  canViewDocuments: (state) => canViewSubmodule(state, 'client_documents'),
  canEditDocuments: (state) => canEditSubmodule(state, 'client_documents'),
  canDeleteDocuments: (state) => canDeleteSubmodule(state, 'client_documents'),
  canViewClientRelationships: (state) => canViewSubmodule(state, 'client_relationships'),
  canEditClientRelationships: (state) => canEditSubmodule(state, 'client_relationships'),
  canEditCorporateClientRelationships: () => true,
  canViewBusinessRelationships: (state) => canViewSubmodule(state, 'view_business_and_direct_relationships'),
  canViewBankAccounts: (state) => canViewSubmodule(state, 'bank_accounts'),
  canEditBankAccounts: (state) => canEditSubmodule(state, 'bank_accounts'),
  canViewMyClientsBasicInformation: (state) => canViewSubmodule(state, 'my_clients_basic_information'),
  canEditMyClientsBasicInformation: (state) => canEditSubmodule(state, 'my_clients_basic_information'),
  canViewPortfolioDetails: (state) => canViewSubmodule(state, 'model_portfolio_details'),
  canDeletePortfolioDetails: (state) => canDeleteSubmodule(state, 'model_portfolio_details'),
  canAccessUserManagement: (state) => hasModule(state, 'user_management'),
  canViewRolesAndPermissionsManager: (state) => canViewSubmodule(state, 'roles_and_permissions_manager'),
  canEditRolesAndPermissionsManager: (state) => canEditSubmodule(state, 'roles_and_permissions_manager'),
  canViewPlatformUsersManager: (state) => canViewSubmodule(state, 'platform_users_manager'),
  canEditPlatformUsersManager: (state) => canEditSubmodule(state, 'platform_users_manager'),
  canViewReportingGroups: (state) => canViewSubmodule(state, 'reporting_group'),
  canDeleteReportingGroups: (state) => canDeleteSubmodule(state, 'reporting_group'),
  canViewPMGroups: (state) => canViewSubmodule(state, 'pm_group'),
  canDeletePMGroups: (state) => canDeleteSubmodule(state, 'pm_group'),
  canViewFeeGroups: (state) => canViewSubmodule(state, 'fee_groups'),
  canDeleteFeeGroups: (state) => canDeleteSubmodule(state, 'fee_groups'),
  canViewAccountFeesConfig: (state) => canViewSubmodule(state, 'account_fees_config'),
  canEditAccountFeesConfig: (state) => canEditSubmodule(state, 'account_fees_config'),
  canViewPlanningSchedules: (state) => canViewSubmodule(state, 'planning_schedules'),
  canDeletePlanningSchedules: (state) => canDeleteSubmodule(state, 'planning_schedules'),
  canViewManagementSchedules: (state) => canViewSubmodule(state, 'management_schedules'),
  canDeleteManagementSchedules: (state) => canDeleteSubmodule(state, 'management_schedules'),
  canViewSubadvisorSchedules: (state) => canViewSubmodule(state, 'subadvisor_schedules'),
  canDeleteSubadvisorSchedules: (state) => canDeleteSubmodule(state, 'subadvisor_schedules'),
  canViewWorkflowsDashboard: (state) => canViewSubmodule(state, 'workflows_dashboard'),
  canViewWorkflowDetails: (state) => canViewSubmodule(state, 'workflow_details'),
  canAccessMoneyMovements: (state) => hasModule('money_movements'),
  canViewMoneyMovementConsolidatedView: (state) => canViewSubmodule(state, 'money_movements_consolidated_view'),
  canEditMoneyMovementConsolidatedView: (state) => canEditSubmodule(state, 'money_movements_consolidated_view'),
  canViewRAPreferences: (state) => canViewSubmodule(state, 'ra_preferences'),
  canEditRAPreferences: (state) => canEditSubmodule(state, 'ra_preferences'),
  canDeleteRAPreferences: (state) => canDeleteSubmodule(state, 'ra_preferences'),
  canViewFundingPage: (state) => canViewSubmodule(state, 'money_movements_funding_page'),
  canEditFundingPages: (state) => canEditSubmodule(state, 'money_movements_funding_page'),
  canDeleteFundingPage: (state) => canDeleteSubmodule(state, 'money_movements_funding_page'),
  canViewRegulationsPage: (state) => canViewSubmodule(state, 'annual_kyc_page'),
  canEditInPersonOnboarding: (state) => canEditSubmodule(state, 'in_person_onboarding'),
  // check accessibility to user level features(modules)
  hasAccessToInsights: (state) => canAccessUserLevelModule(state, 'insights'),
  hasAccessToConquest: (state) => canAccessUserLevelModule(state, 'conquest'),
  canAccessHouseholds: (state) => true, // FIXME: Proper submodule permission to implemented
  canAccessIpsGroups: (state) => canViewSubmodule(state, 'ips_group_generic'),
  canEditAccountOpeningApprovalStatus: (state) => canEditSubmodule(state, 'account_opening_workflow_approval_status'),
  hasFullWorkflowViewPermission: (state) => canViewSubmodule(state, 'full_workflow_view'),
  hasFullWorkflowEditPermission: (state) => canEditSubmodule(state, 'full_workflow_view'),
  hasFullWorkflowDeletePermission: (state) => canDeleteSubmodule(state, 'full_workflow_view'),
  canEditIpsWorkflow: (state) => canEditSubmodule(state, 'ips_workflow'),
  canEditIpsGroupWorkflow: (state) => canEditSubmodule(state, 'ips_group'),
};
export default {
  state: defaultState,
  mutations,
  actions,
  getters,
};
