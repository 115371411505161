<template>
  <div>
    <WorkflowStep :isCanceled="workflow.status === 'canceled'" :step="step">
      <span v-if="isManualWorkflow && hasFullWorkflowEditPermission" @click.prevent="completeSigning" class="action-text">
        Mark as complete
      </span>
      <span v-if="!isManualWorkflow && (workflow.type !== 'ips_workflow' || canEditIpsWorkflow)" @click.prevent="openResendModal" class="action-text">Re-send package</span>
    </WorkflowStep>

    <ConfirmActionDialog
      @close="resendModalOpen = false"
      @ok="resendPackage"
      :isOpen="resendModalOpen"
      :disableAction="resendInProgress"
      :width="'400px'"
    >
      <template v-slot:header>Resend client package</template>
      Are you sure you want to resend the DocuSign package to your client?
      <br />
      <br />
      <template v-slot:btn>Yes</template>
    </ConfirmActionDialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { sendDocumentSigningSuccess, resendDocuSignPackage } from 'Services/api/Workflows';
import ConfirmActionDialog from 'Components/Modals/ConfirmActionDialog';
import WorkflowStep from './WorkflowStep';

export default {
  name: 'ClientSigningStep',
  components: { WorkflowStep, ConfirmActionDialog },
  props: {
    workflow: {
      type: Object,
      required: true,
    },
    step: {
      type: Object,
      default: () => {},
    },
    isManualWorkflow: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      resendModalOpen: false,
      resendInProgress: false,
    };
  },
  computed: {
    ...mapGetters([
      'activeClient',
      'hasFullWorkflowEditPermission',
      'canEditIpsWorkflow',
    ]),
  },
  methods: {
    openResendModal() {
      this.resendInProgress = false;
      this.resendModalOpen = true;
    },
    async completeSigning() {
      try {
        const res = await sendDocumentSigningSuccess(this.workflow, 'client');
        if (res.status === 200) {
          this.$store.dispatch('setSnackbar', {
            text: 'Updating Workflow',
            type: 'success',
          });
          this.$store.dispatch('flipSnackbarSwitch');
          await this.$store.dispatch('fetchWorkflowV2Steps', this.workflow.id);
        }
      } catch (err) {
        console.log(err);
        this.$store.dispatch('setSnackbar', {
          text: 'Fail to update workflow',
          type: 'error',
        });
        this.$store.dispatch('flipSnackbarSwitch');
      }
    },
    async resendPackage() {
      try {
        this.resendInProgress = true;
        const res = await resendDocuSignPackage(this.workflow);
        this.resendModalOpen = false;
        if (res.status === 200) {
          this.$store.dispatch('setSnackbar', {
            text: 'Package resent successfully',
            type: 'success',
          });
          this.$store.dispatch('flipSnackbarSwitch');
        }
      } catch (err) {
        this.resendModalOpen = false;
        console.log(err);
        this.$store.dispatch('setSnackbar', {
          text: 'Fail to resent package',
          type: 'error',
        });
        this.$store.dispatch('flipSnackbarSwitch');
      }
    },
  },
};
</script>

<style scoped>

</style>
